import FujiScannerImmexInput from "../inputs/FujiScannerImmexInput";
import useFetchClick from "../../hooks/useFetchClick";

const getQtyToDiscount=(stockQty, cartQty, pickupQty)=>{
    const missingQty = cartQty - pickupQty;
    if(missingQty>=stockQty)return stockQty;
    return missingQty;
}

export default function ImmexCheckoutItemPedimentoRow({ rowData, partnumber, checkout_items_id, dashboardRequest, cartQty, pickupQty }) {
    const  setQrMessageError= (msg)=>{
        alert(msg);
    }

    const submitDiscount=(e)=>{
        e.preventDefault();
        pickupDiscountCheckout.handleRequest();
    }
    const pickupDiscountCheckout = useFetchClick(
        `${process.env.REACT_APP_API_URL}pickup_discount_checkout`,
        {
            pedimento_partnumber_id:rowData.immex_pedimento_partnumber_id,
			checkout_items_id:checkout_items_id,
			qty:getQtyToDiscount(rowData.current_qty, cartQty, pickupQty)
        }, dashboardRequest);

    const checkQr = (pn, qty, jpid) => {
        if (pn !== partnumber) {
            alert(`Wrong Partnumber:${pn}, Expected value:${partnumber}`)
        }
        else if (qty !== rowData.package_qty) {
            alert(`Wrong Quantity:${qty}, Expected value:${rowData.package_qty}`)
        }
        else if (jpid !== rowData.jp_id) {
            alert(`Wrong Quantity:${jpid}, Expected value:${rowData.jp_id}`)
        }
        else {
            pickupDiscountCheckout.handleRequest();
        }
    }
    console.log(rowData)
    return (
        <tr className="hover">
            <td>{rowData.jp_id ? rowData.jp_id : "EMPTY"}</td>
            <td>{rowData.location ? rowData.location : "UNKNOWN"}</td>
            <td><div className="badge badge-outline badge-base-100 text-lg">{rowData.current_qty}</div></td>
            <td><div className={!parseInt(rowData.discount_qty) ? "badge badge-outline badge-base-100 text-lg" : "badge badge-success text-lg"}>{rowData.discount_qty}</div></td>
            <td>
                <div className="flex">
                    {
                        !rowData.jp_id ?
                            <button
                                onClick={submitDiscount}
                                disabled={parseInt(rowData.discount_qty)}
                                className="btn btn-square btn-outline btn-sm btn-warning ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>
                            </button> :
                            <button
                            onClick={submitDiscount}
                            disabled={parseInt(rowData.discount_qty)}
                            className="btn btn-square btn-outline btn-sm btn-warning ">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                            </svg>
                        </button>
                            /*<FujiScannerImmexInput 
                                disabled={parseInt(rowData.discount_qty)}
                                checkQr={checkQr} 
                                setQrMessageError={setQrMessageError}
                                style="input input-bordered input-sm w-8 input-warning" />*/
                    }

                </div>

            </td>
        </tr>
    );
}