import { useState } from "react";
import useFetch from "../../hooks/useFetch";
import useFetchClick from "../../hooks/useFetchClick";
import { statusToColor } from "../../utils/constants";
import DocsViewer from "../form/DocsViewer";
import DeleteCard from "../form/DeleteCard";
import RejectCard from "../form/RejectCard";

import { format, parse } from "date-fns";
import { dateFormatDB } from "../../utils/constants";
import AddAndUpdateInvoice from "../form/AddAndUpdateInvoice";

export default function InvoiceRow({ rowData, isAdmin, dashboardRequest }) {
    const [showDel, setShowDel] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [showDocs, setShowDocs] = useState(false);
    const cardId = rowData.id;
    const bodyFetch = { "cardId": cardId };
    const { loading: loadingApproved, error: errorApproved, handleRequest } = useFetchClick(
        `${process.env.REACT_APP_API_URL}approved_expense`,
        {
            "id": cardId
        },
        dashboardRequest
    )
    const { data: filesData, loading: filesLoading, error: filesError, handleRequest: handleGetFiles } = useFetch(
        `${process.env.REACT_APP_API_URL}expense_files`,
        bodyFetch
    )
    const approvedClickHandle = () => {
        handleRequest()
    }
    const rejectedClickHandle = () => {
        setShowReject(true);
    }
    const docsClickHandle = () => {
        setShowDocs(true);
        handleGetFiles();
    }

    return (
        <tr className="hover">
            <td className={statusToColor[rowData.status]} />
            <td>{cardId}</td>
            <td><div className="badge badge-outline badge-primary whitespace-nowrap">{rowData.principal}</div></td>
            <td><div className="badge badge-outline badge-secondary whitespace-nowrap">{rowData.client_name}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2">{rowData.user_name}</div></td>
            <td><div className="badge badge-outline badge-primary whitespace-nowrap">{rowData.expense_type}</div></td>
            <td><div className="bg-gray-900 rounded-lg p-2 text-white">{rowData.concept}</div></td>
            <td>
                {
                    rowData.denial_msg ?
                        <div className="bg-red-900 rounded-lg p-2 text-white">{rowData.denial_msg}</div> :
                        <div className="bg-gray-200 rounded-lg p-2 text-gray-600">N/A</div>
                }
            </td>
            <td><div className="badge badge-outline badge-base-100 text-lg">${rowData.amount}</div></td>
            <td><div className="badge badge-outline badge-secondary">{rowData.expense_datetime}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2">{rowData.client_city}</div></td>
            <td><div className="badge badge-outline badge-base-100 text-lg">{rowData.files_qty}</div></td>
            <td>
                <div className="flex">
                    {
                        isAdmin && <>
                            {
                                rowData.status !== 'APPROVED' &&
                                (<button
                                    className="btn btn-square btn-outline btn-sm btn-success mr-2"
                                    onClick={approvedClickHandle}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>
                                </button>)
                            }
                            {
                                rowData.status !== 'REJECTED' &&
                                (<button
                                    onClick={rejectedClickHandle}
                                    className="btn btn-square btn-outline btn-sm btn-warning mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>
                                </button>)
                            }
                        </>
                    }
                    {((rowData.status !== "APPROVED") || isAdmin) &&
                        (<button
                            onClick={() => setShowUpdate(true)}
                            className="btn btn-square btn-outline btn-sm btn-info mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" /></svg>
                        </button>)
                    }
                    <button
                        onClick={docsClickHandle}
                        className="btn btn-square btn-outline btn-sm btn-info mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4v3a1 1 0 0 1-1 1h-3m2 10v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L6.7 8.35A1 1 0 0 1 7.46 8H9m-1 4H4m16-7v10a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7.87a1 1 0 0 1 .24-.65l2.46-2.87a1 1 0 0 1 .76-.35H19a1 1 0 0 1 1 1Z" /></svg>
                    </button>

                    {((rowData.status !== "APPROVED") || isAdmin) &&
                        (<button
                            onClick={() => setShowDel(true)}
                            className="btn btn-square btn-outline btn-sm btn-error">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" /></svg>
                        </button>)
                    }

                    {showDocs &&
                        (<DocsViewer
                            handleGetFiles={handleGetFiles}
                            data={filesData}
                            loading={filesLoading}
                            handleForm={setShowDocs}
                            isAdmin={isAdmin}
                            cardId={cardId}
                            cardStatus={rowData.status} />)
                    }
                    <DeleteCard
                        show={showDel}
                        handleForm={setShowDel}
                        id={cardId}
                        dashboardRequest={dashboardRequest}
                        name="Expense" />
                    <RejectCard
                        concept={rowData.concept}
                        userId={rowData.user_id}
                        dt={format(parse(rowData.expense_datetime, 'dd/MM/yyyy', new Date()), dateFormatDB)}
                        show={showReject}
                        handleForm={setShowReject}
                        cardId={cardId}
                        dashboardRequest={dashboardRequest}
                        name="Expense" />
                    <AddAndUpdateInvoice
                        show={showUpdate}
                        handleForm={setShowUpdate}
                        userName={rowData.user_name}
                        dashboardRequest={dashboardRequest}
                        status={rowData.status}
                        startBody={{
                            "user_id": rowData.user_id,
                            "id": cardId,
                            "principal": rowData.principal,
                            "client_name": rowData.client_name,
                            "expense_type": rowData.expense_type,
                            "datetime_obj": parse(rowData.expense_datetime, 'dd/MM/yyyy', new Date()),
                            "datetime": format(parse(rowData.expense_datetime, 'dd/MM/yyyy', new Date()), dateFormatDB),
                            "amount": rowData.amount,
                            "concept": rowData.concept,
                            "denial_msg": rowData.denial_msg,
                            "files": []
                        }}
                        url="update_expense_files"
                        title="Edit Expense"
                        isAdmin={isAdmin}
                        isRejected={rowData.status === "REJECTED"}
                        datePickerHidden={true} />
                </div>

            </td>
            <td className={statusToColor[rowData.status]}/>
            {errorApproved && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{errorApproved}</span>
                    </div>)}
                    {filesError && (<div role="alert" className="flex mt-4 rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{filesError}</span>
                    </div>)}
                    {
                        loadingApproved &&
                        (<progress className="progress w-full progress-info mt-4"></progress>)
                    }
        </tr>
    );
}