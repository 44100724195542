import ReactApexChart from 'react-apexcharts';

const setOptions = (value, valueTitle, progressColor)=> {
    return {
    series: [value],
    colors: [progressColor],
    chart: {
        height: 320,
        width: "100%",
        type: "radialBar",
        sparkline: {
            enabled: true,
        },
    },
    plotOptions: {
        radialBar: {
            track: {
                background: '#E5E7EB',
            },
            dataLabels: {
                show: true,
                name: {
                    show: false
                  },
                  value: {
                    show: true,
                    fontSize: '30px',
                    fontFamily: undefined,
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 8,
                    formatter: function (val) {
                      return val + '%'
                    }
                  }
            },
            hollow: {
                margin: 0,
                size: "50%",
            },
        },
    },
    grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
            left: 2,
            right: 2,
            top: -23,
            bottom: -20,
        },
    },
    labels: [valueTitle],
    legend: {
        show: true,
        position: "bottom",
        fontFamily: "Inter, sans-serif",
    },
    tooltip: {
        enabled: true,
        x: {
            show: false,
        },
    },
    yaxis: {
        show: false,
        labels: {
            formatter: function (value) {
                return value + '%';
            }
        }
    }
}
}

export default function ProgressRadialChart(props) {
    const { value, chartTitle, valueTitle } = props;
    const progressColor = value > 100 ? "#FF0000" : "#00FF00";
    const chartOptions = setOptions(value.toFixed(3), valueTitle, progressColor);


    return (
        <div className='w-full bg-base-100 rounded-xl mt-6 px-2 lg:px-10 pt-6 pb-6 shadow'>

            <div className="flex justify-center items-center">
                <h5 className="text-xl font-bold leading-none pe-1">{chartTitle}</h5>
            </div>

            <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="radialBar"
                height={350}
            />
        </div>
    );
}

