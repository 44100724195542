export default function GridCards(props) {
    const { cardsData, GridCard } = props;
    return (
        <div className="grid grid-flow-row auto-rows-max gap-6 lg:grid-cols-3">
            {
                cardsData.map((value, ind)=>
                    <GridCard key={ind} cardData={value} {...props}/>
                )
            }
        </div>
    );
}