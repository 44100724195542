import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../../components/tables/TableOrGrid";
import OrganizationChart from "../../components/charts/OrganizationChart";
import UsersViewerUserRow from "../../components/tables/UsersViewerUserRow";
import OrganizationWall from "../../components/charts/OrganizationWall";
import { getUserData } from "../../utils/utils";
import ColumnChart from "../../components/charts/ColumnChart";
export default function UsersViewer(props) {
    const userInfo = getUserData();
    const [orgId, setOrgId] = useState(userInfo.organizationId);

    const { data: dataChart, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}organization_chart`,
        {
            "organization_id": orgId
        }
    )

    useEffect(() => {
        handleRequest();
    }, [orgId])
    console.log(dataChart)
    return (
        <div>
            <OrganizationWall
                orgId={orgId}
                setOrgId={setOrgId}
                dashboardRequest={handleRequest}
                isAdmin={userInfo.isUserViewerAdmin} />
            <TableOrGrid
                sessionUserId = {userInfo.userId}
                loading={loading}
                isGrid={false}
                data={dataChart}
                GridCard={null}
                isAdmin={userInfo.isUserViewerAdmin}
                dashboardRequest={handleRequest}
                TableRow={UsersViewerUserRow}
                TableColumns={<tr>
                    <th>ID</th>
                    <th>Profile</th>
                    <th>User Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>City</th>
                    <th>Department</th>
                    <th>Position</th>
                    <th>Day By Day</th>
                    <th>Vacation</th>
                    <th>Birthday</th>
                    <th>Hired</th>
                    <th></th>
                </tr>} />
            {
                /*            
                            <OrganizationChart
            isAdmin={userInfo.isUserViewerAdmin}
            sessionUserId = {userInfo.userId}
            dataChart={dataChart}
            orgId={orgId}
            loading={loading}
            dashboardRequest={handleRequest} /> 
                <ColumnChart
            title={"Employee Age Chart"}
            filterValues={{
                "organization_id": orgId
            }}
            url={"get_users_by_year"}
            valueName={"No. Employees"} />
        <ColumnChart
            title={"Employee Hired Chart"}
            filterValues={{
                "organization_id": orgId
            }}
            url={"get_users_by_hired"}
            valueName={"No. Employees"} />*/
            }

        </div>
    );
}