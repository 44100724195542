import { useEffect, useState } from "react";
import ImmexPartnumbersCard from "../../components/tables/ImmexPartnumbersCard";
import ImmexPartnumberRow from "../../components/tables/ImmexPartnumberRow";
import useFetch from "../../hooks/useFetch";
import HeadStatsImmexPartnumber from "../../components/ui/HeadStatsImmexPartnumber";
import BarImmexPartnumbers from "../../components/ui/BarImmexPartnumbers";
import { getUserData } from "../../utils/utils";
import TableOrGrid from "../../components/tables/TableOrGrid";

const getSumList = (data, key) => {
    const bomCost = data.reduce((total, row) => {
        return total + parseFloat(row[key])
    }, 0)

    return bomCost;
}

export default function ImmexPartnumbers() {
    const [pn, setPn] = useState("");

    const userInfo = getUserData();

    const getImmexPartnumber = useFetch(`${process.env.REACT_APP_API_URL}get_immexpartnumbers`, { partnumber: pn })
    const getImmexShopingCart = useFetch(`${process.env.REACT_APP_API_URL}get_immex_shoping_cart`, { userId: userInfo.userId })

    const refreshAllApis = () =>{
        getImmexPartnumber.handleRequest()
        getImmexShopingCart.handleRequest()
    }
    useEffect(() => {
        refreshAllApis();
    }, [])

    
    return (
        <div className="w-full">
            <HeadStatsImmexPartnumber qty={getSumList(getImmexPartnumber.data, "stock_qty")} pns={getImmexPartnumber.data.length} />
            <BarImmexPartnumbers refreshPage={refreshAllApis}
                setPn={setPn}
                pn={pn}
                shopingCartData={getImmexShopingCart.data}
                userInfo={userInfo}
                />
            <TableOrGrid
                loading={getImmexPartnumber.loading}
                isGrid={false}
                data={getImmexPartnumber.data}
                GridCard={ImmexPartnumbersCard}
                isAdmin={userInfo}
                dashboardRequest={refreshAllApis}
                TableRow={ImmexPartnumberRow}
                TableColumns={<tr>
                    <th>Image</th>
                    <th>PartNumber</th>
                    <th>Description</th>
                    <th>Metric Unit</th>
                    <th>HTS</th>
                    <th>Pedimentos</th>
                    <th>JP ID</th>
                    <th>Stock Qty</th>
                    <th>Process Qty</th>
                    <th>Available Qty</th>
                    <th></th>
                </tr>}/>

        </div>
    );
}

