
import { useState } from "react";
import { CellNull } from "./CellNull";
import AddAndUpdateBomPart from "../form/AddAndUpdateBomPart";
import DeleteCard from "../form/DeleteCard";
import PartViewer from "../form/PartViewer";

export default function LcuBomRow({ rowData, isAdmin, dashboardRequest }) {
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showImage, setShowImage] = useState(false);
    return (
        <tr className="hover">
             <td>
                <button
                    disabled={rowData.image_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td><div className="badge badge-neutral whitespace-nowrap font-bold">{rowData.partnumber}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.description}</div></td>
            <td><div className="badge badge-primary whitespace-nowrap font-bold">{rowData.vendor}</div></td>
            <td><div className="badge badge-success whitespace-nowrap font-bold">{rowData.manufacturer}</div></td>
            <td><div className="badge  whitespace-nowrap font-bold">{rowData.category}</div></td>
            <td>
                {rowData.link ?
                    <a className="text-blue-600 font-bold" href={rowData.link} target="_blank" rel="noreferrer">
                        LINK
                    </a> : CellNull
                }
            </td>
            <td>
                {rowData.location_tag ?
                    <p>
                        {rowData.location_tag}
                    </p> : CellNull
                }
            </td>

            <td>
                <div className={rowData.stock_qty ? "badge badge-info" : "badge badge-error"}>
                    {rowData.stock_qty}
                </div>
            </td>
            <td>
                <div className={rowData.bom_qty ? "badge badge-info" : "badge badge-error"}>
                    {rowData.bom_qty}
                </div>
            </td>
            <td>
                <div className={rowData.bom_units ? "badge badge-success" : "badge badge-error"}>
                    {rowData.bom_units}
                </div>
            </td>

            <td>{rowData.stock_cost}</td>
            <td>{rowData.bom_cost}</td>
            <td>{rowData.unit_cost}</td>

            <td className="w-24">
                <button
                    disabled={!(isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowUpdate(true)}
                    className="btn btn-square btn-outline btn-sm btn-info mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                </button>
                <button
                    disabled={!(isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowDelete(true)}
                    className="btn btn-square btn-outline btn-sm btn-error ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                </button>
                    {
                        showUpdate ?
                            <AddAndUpdateBomPart
                                handleForm={setShowUpdate}
                                dashboardRequest={dashboardRequest}
                                startBody={{
                                    qty: rowData.bom_qty,
                                    bom_part_id: rowData.bom_part_id
                                }}
                                url="update_partnumber_to_bom_qty"
                                isNew={false} /> :
                            null
                    }
                    {
                        showImage ?
                            <PartViewer
                                handleForm={setShowImage}
                                id={rowData.partnumber_id}
                                url="get_partnumber_image" /> :
                            null
                    }
                    <DeleteCard
                        show={showDelete}
                        handleForm={setShowDelete}
                        id={rowData.bom_part_id}
                        dashboardRequest={dashboardRequest}
                        name="PartnumberBom" />
            </td>
        </tr>
    );
}