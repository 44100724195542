import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import SelectClients from "../../components/form/selectClients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faChevronDown,
    faUserShield,
    faUser,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function PoForm(props) {
    const { row, changeTab2 } = props;
    const [isActive, setIsActive] = useState(false);
    
	var curr = row ? new Date(row.date) : new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);

	console.log(date);
	

	const [clientSelected, setClientSelected] = useState();
	const changeTab = index => {
		console.log(index);
		//changeTab2(index)
		setClientSelected(index.id);
	};

    /*const options = [
        { value: "chocolate", label: "Chocolate", custom: "dsa" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    const [selectedOption, setSelectedOption] = useState([]);
    changeTab(selectedOption);

    console.log(selectedOption);

    var currentArea = "";
    const endpoint = process.env.REACT_APP_API_URL + "clientsForSelect";
    const { data, loading, error, refetch } = useFetchGet(endpoint, {
        appName: "schedule",
    });
    //console.log(data);

    if (loading) return <h1>LOADING...</h1>;

    if (error) console.error(error);*/

    return (
        <form>
            <div className="border-1 grid grid-cols-2 gap-6 p-2">

            <div className={row ? "form-control w-full" : "hidden"}>
                <label className="label">
                    <span className="label-text">Id</span>
                </label>
                <input
                    type="text"
                    className= "input input-bordered w-full"
                    name="id"
                    id="id"
                    disabled
                    defaultValue={row ? row.id : ""}
                />
            </div>

							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Select Client</span>
								</label>
								<SelectClients changeTab={changeTab} clientSelected={{value: row ? row.client.name : "", label: row ? row.client.name : ""}} />
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Date</span>
								</label>
								<input
									type="date"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="date"
									id="date"
									defaultValue={date} 
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">PO #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="po"
									id="po"
									defaultValue={row ? row.po : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Quote #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="quote"
									id="quote"
									defaultValue={row ? row.quote : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Hours</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="hours"
									id="hours"
									defaultValue={row ? row.hours : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Hours Left</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="hoursLeft"
									id="hoursLeft"
									defaultValue={row ? row.hoursLeft : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Cost</span>
								</label>
								<input
									type="number"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="cost"
									id="cost"
									defaultValue={row ? row.cost : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Cost Left</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="costLeft"
									id="costLeft"
									defaultValue={row ? row.costLeft : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Currency</span>
								</label>
								<select className="select w-full select-bordered" name="currency" id="currency" defaultValue={row ? row.currency : ""}>
									<option disabled selected>Select currency</option>
									<option>Dolares</option>
									<option>Pesos</option>
								</select>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Invoice #</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="invoice"
									id="invoice"
									defaultValue={row ? row.invoice : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Requisitor</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full "
									name="requisitor"
									id="requisitor"
									defaultValue={row ? row.requisitor : ""}
								/>
							</div>

							<div className="form-control w-full ">
								<label className="label">
									<span className="label-text">Status</span>
								</label>
								<select className="select w-full select-bordered" name="status" id="status" defaultValue={row ? row.status : ""}>
									<option disabled selected>Select status</option>
									<option>Active</option>
									<option>Expired</option>
									<option>Terminated</option>
								</select>
							</div>

							<div className="form-control w-full">
								<label className="label">
									<span className="label-text">Client Id</span>
								</label>
								<input
									type="text"
									placeholder="Type here"
									className="input input-bordered w-full"
									name="clientId"
									id="clientId"
									disabled
									defaultValue={row ? row.client.id : clientSelected}
								/>
							</div>

						</div>
        


        </form>
    );
}

export default PoForm;
