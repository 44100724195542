import { useState } from "react";
import DeleteCard from "../form/DeleteCard";
import ImmexPickup from "../form/ImmexPickup";
import ImmexDelivery from "../form/ImmexDelivery";
import useFetchClick from "../../hooks/useFetchClick";
import PartViewer from "../form/PartViewer";

const selectButton = (data) => {
    if (data.pickup_datetime) {
        return 'Delivery'
    }
    else if (data.create_datetime) {
        return 'Pickup'
    }

    else {
        return ''
    }
}

const dateTimeFixFormat = (dateTime) => {
    return dateTime.slice(0, 10)
    //const noTimezone = dateTime.slice(0,-5);
    //const noTCharacter = noTimezone.replace('T',' ');
    //return noTCharacter
}

const dateColor = (deliveryStatus, controlNull) => {
    if (!controlNull) {
        return {
            style: "badge text-lg font-bold",
            text: "N/A"
        }
    }
    if (deliveryStatus) {
        return {
            style: "badge badge-success text-lg font-bold",
            text: dateTimeFixFormat(controlNull)
        }
    }
    else {
        return {
            style: "badge badge-warning text-lg font-bold",
            text: dateTimeFixFormat(controlNull)
        }
    }
}


export default function ImmexCheckoutRow({ rowData, isAdmin, dashboardRequest }) {
    const [showDelete, setShowDelete] = useState(false);
    const [showPickup, setShowPickup] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [showDelivery, setShowDelivery] = useState(false);
    const nextState = selectButton(rowData);

    const createBadge = dateColor(rowData.delivery_datetime, rowData.create_datetime);
    const pickupBadge = dateColor(rowData.delivery_datetime, rowData.pickup_datetime);
    const deliveryBadge = dateColor(rowData.delivery_datetime, rowData.delivery_datetime);

    const selectButtonHandle = () => {
        if (nextState === 'Pickup') {
            setShowPickup(true);
        }
        else if (nextState === 'Delivery') {
            setShowDelivery(true);
        }
        else {
            alert("Unknown nextstate")
        }
    }
    return (
        <tr className="hover">
            <td><div className="badge badge-primary font-bold">{rowData.immex_checkout_id}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal ">{rowData.user_name}</div></td>
            <td><div className="bg-gray-900 rounded-lg p-2 text-white  font-bold">{rowData.client_name}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal ">{rowData.po}</div></td>
            <td><div className="badge badge-outline badge-base-100  font-bold">{rowData.qty}</div></td>
            <td><div className="badge badge-outline badge-base-100  font-bold">{rowData.partnumbers}</div></td>
            <td><div className={createBadge.style}>{createBadge.text}</div></td>
            <td><div className={pickupBadge.style}>{pickupBadge.text}</div></td>
            <td><div className={deliveryBadge.style}>{deliveryBadge.text}</div></td>
            <td>
                <div className="flex flex-row gap-2">
                    <button
                        onClick={() => selectButtonHandle()}
                        disabled={rowData.delivery_datetime || !(isAdmin.userImmexRole === 'admin')}
                        className="btn btn-outline btn-success btn-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" /></svg>
                        {nextState}
                    </button>
                    <button
                        disabled={rowData.pickup_datetime || !(isAdmin.userImmexRole === 'admin')}
                        onClick={() => setShowDelete(true)}
                        className="btn btn-square btn-outline btn-sm btn-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                    <button
                        disabled={!rowData.delivery_file_name}
                        onClick={() => setShowImage(true)}
                        className="btn btn-square btn-outline btn-sm btn-success">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                        </svg>
                    </button>
                    <button
                        disabled={!rowData.pickup_datetime}
                        onClick={() => setShowPickup(true)}
                        className="btn btn-square btn-outline btn-sm btn-info">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </button>
                </div>
            </td>
            <DeleteCard
                show={showDelete}
                handleForm={setShowDelete}
                id={rowData.immex_checkout_id}
                dashboardRequest={dashboardRequest}
                name="ImmexCheckout" />
            {
                showPickup ?
                    <ImmexPickup
                        handleForm={setShowPickup}
                        refreshPage={dashboardRequest}
                        checkoutData={rowData}
                        withoutSubmit={rowData.pickup_datetime} /> :
                    null
            }
            {
                showDelivery ?
                    <ImmexDelivery
                        handleForm={setShowDelivery}
                        refreshPage={dashboardRequest}
                        checkoutData={rowData} /> :
                    null
            }
            {
                showImage ?
                    <PartViewer
                        title="Delivery Bill"
                        handleForm={setShowImage}
                        id={rowData.immex_checkout_id}
                        url="get_checkout_delivery_file"
                        reduceSize={false} /> :
                    null
            }
        </tr>
    );
}