
import { useState } from "react";
import { CellNull } from "./CellNull";
import AddAndUpdateLcuBox from "../form/AddAndUpdateLcuBox";
import DeleteCard from "../form/DeleteCard";

export default function LcuBoxRow({ rowData, isAdmin, dashboardRequest }) {
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    
    return (
        <tr className="hover">
            <td><div className="badge badge-outline badge-primary">{rowData.lcu_id}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.serialnumber}</div></td>
            <td>{!rowData.created_datetime ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.created_datetime.slice(0, 10)}</div>}</td>
            <td>{!rowData.mac_address_nexim ? CellNull : <div className="badge badge-info">{rowData.mac_address_nexim}</div>}</td>
            <td>{!rowData.delivery_count ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.delivery_count}</div>}</td>

            <td>
                <button
                    disabled={!(!parseInt(rowData.delivery_count) && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowUpdate(true)}
                    className="btn btn-square btn-outline btn-sm btn-info mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                </button>
                <button
                    disabled={!(!parseInt(rowData.delivery_count) && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowDelete(true)}
                    className="btn btn-square btn-outline btn-sm btn-error ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                </button>
                {
                    showUpdate ?
                        <AddAndUpdateLcuBox
                            handleForm={setShowUpdate}
                            refreshPage={dashboardRequest}
                            url='/update_lcu_box'
                            defaultBody={{
                                lcuId: parseInt(rowData.lcu_id),
                                lcuBox: rowData.serialnumber
                            }} /> :
                        null
                }
                {
                    showDelete ?
                        <DeleteCard
                            show={showDelete}
                            handleForm={setShowDelete}
                            id={rowData.lcu_id}
                            dashboardRequest={dashboardRequest}
                            name="lcubox" /> :
                        null
                }
            </td>
        </tr>
    );
}