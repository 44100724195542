import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faChevronDown,
    faUserShield,
    faUser,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import MexicoStates from "../inputs/MexicoStates";

function ClientForm(props) {
    const { row } = props;
    const [isActive, setIsActive] = useState(false);

    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    var date = curr.toISOString().substring(0,10);

    return (
        <form>
<div className="border-1 grid grid-cols-2 gap-6 p-2">
            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Id</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="id"
                    id="id"
                    disabled
                    defaultValue={row ? row.id : 0}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Name</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="name"
                    id="name"
                    defaultValue={row ? row.name : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Razon Social</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="razonSocial"
                    id="razonSocial"
                    defaultValue={row ? row.razonSocial : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">RFC</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="rfc"
                    id="rfc"
                    defaultValue={row ? row.rfc : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Calle</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="calle"
                    id="calle"
                    defaultValue={row ? row.calle : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">CP</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    name="cp"
                    id="cp"
                    defaultValue={row ? row.cp : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Colonia</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="colonia"
                    id="colonia"
                    defaultValue={row ? row.colonia : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Ciudad</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="ciudad"
                    id="ciudad"
                    defaultValue={row ? row.ciudad : ""}
                />
            </div>

            <div className="form-control w-full ">
                <MexicoStates defaultValue={row ? row.estado : ""} id="estado" name="estado" />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Pais</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="pais"
                    id="pais"
                    defaultValue={row ? row.pais : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Telefono</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="telefono"
                    id="telefono"
                    defaultValue={row ? row.telefono : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">FSE</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="fse"
                    id="fse"
                    defaultValue={row ? row.fse : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Moneda</span>
                </label>
                <select className="select select-bordered w-full" name="moneda" id="moneda" defaultValue={row ? row.moneda : ""}>
                    <option selected>Peso Mexicano</option>
                    <option>Dolar Americano</option>
                </select>
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Creator</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="rowCreator"
                    id="rowCreator"
                    disabled
                    defaultValue={row ? row.rowCreator : localStorage.getItem("userName")}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Latitude</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    name="latitude"
                    id="latitude"
                    defaultValue={row ? row.latitude : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Longitude</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="longitude"
                    id="longitude"
                    defaultValue={row ? row.longitude : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Plant #</span>
                </label>
                <input
                    type="number"
                    className="input input-bordered w-full"
                    name="plant"
                    id="plant"
                    defaultValue={row ? row.plant : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Credits</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="credits"
                    id="credits"
                    defaultValue={row ? row.credits : ""}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">Report Installation Date</span>
                </label>
                <input
                    type="date"
                    className="input input-bordered w-full"
                    name="reportInstalationDate"
                    id="reportInstalationDate"
                    defaultValue={row ? row.reportInstalationDate : date}
                />
            </div>

            <div className="form-control w-full ">
                <label className="label">
                    <span className="label-text">PO #</span>
                </label>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    name="po"
                    id="po"
                    defaultValue={row ? row.po : ""}
                />
            </div>



            

            </div>
        </form>
    );
}

export default ClientForm;
