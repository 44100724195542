import { useState } from "react";
import DeleteCard from "../form/DeleteCard";

const NABadge = <div className="badge badge-neutral text-lg">N/A</div>

const componentIcon = (style, iconD, Value) => {
    return <div className={style}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block h-4 w-4 stroke-current">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={iconD}></path>
        </svg>
        <div className="ml-2 badge-outline badge badge-sm">{Value}</div>

    </div>
}

const monthStatus = (status, value) => {
    if (status === 'green') {
        return componentIcon(
            "badge badge-lg badge-success",
            "M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
            value)
    }
    if (status === "red") {
        return componentIcon(
            "badge badge-lg badge-error",
            "m10.051 8.102-3.778.322-1.994 1.994a.94.94 0 0 0 .533 1.6l2.698.316m8.39 1.617-.322 3.78-1.994 1.994a.94.94 0 0 1-1.595-.533l-.4-2.652m8.166-11.174a1.366 1.366 0 0 0-1.12-1.12c-1.616-.279-4.906-.623-6.38.853-1.671 1.672-5.211 8.015-6.31 10.023a.932.932 0 0 0 .162 1.111l.828.835.833.832a.932.932 0 0 0 1.111.163c2.008-1.102 8.35-4.642 10.021-6.312 1.475-1.478 1.133-4.77.855-6.385Zm-2.961 3.722a1.88 1.88 0 1 1-3.76 0 1.88 1.88 0 0 1 3.76 0Z",
            value)
    }
    if (status === "amber") {
        return componentIcon(
            "badge badge-lg badge-warning",
            "M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z",
            value)
    }
    if (status === "dark") {
        return NABadge
    }

}


export default function ImmexPedimentoRow({ rowData, dashboardRequest, setPedimentoSelected, noActions = false, isAdmin }) {
    const [showDelete, setShowDelete] = useState(false);
    //const [showUpdate, setShowUpdate] = useState(false);
    const viewPedimento = (row) => {
        setPedimentoSelected(row);
        dashboardRequest();
    }
    return (
        <tr className="hover">
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal text-lg">{rowData.pedimento}</div></td>
            <td>{rowData.is_temporary ? <div className="badge badge-success gap-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block h-4 w-4 stroke-current">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                </svg>
            </div> : <div className="badge badge-warning gap-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block h-4 w-4 stroke-current">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                </svg>
            </div>
            }
            </td>
            <td><div className="badge text-lg">{rowData.import_date.slice(0, 10)}</div></td>
            <td>
                {
                    !rowData.required_export_date || rowData.export_date ?
                        NABadge :
                        <div className="badge badge-secondary text-lg">{rowData.required_export_date.slice(0, 10)}</div>
                }
            </td>
            <td>{
                !rowData.export_date ?
                    NABadge :
                    <div className="badge badge-success text-lg">{rowData.export_date.slice(0, 10)}</div>
            }
            </td>
            <td>{monthStatus(rowData.status, rowData.months_passed)}</td>
            <td><div className={parseInt(rowData.partidas) ? "badge badge-outline badge-base-100 text-lg" : "badge badge-error text-lg"}>{rowData.partidas}</div></td>
            <td><div className={parseInt(rowData.total_locations) === parseInt(rowData.partidas) ? "badge  badge-success text-lg" : "badge badge-error text-lg"}>{rowData.total_locations}</div></td>
            <td><div className={parseInt(rowData.total_audit) === parseInt(rowData.partidas) ? "badge  badge-success text-lg" : "badge badge-error text-lg"}>{rowData.total_audit}</div></td>
            <td><div className="badge badge-outline badge-base-100 text-lg">{rowData.partnumbers}</div></td>
            <td><div className="badge badge-outline badge-base-100 text-lg">{rowData.package_qty}</div></td>
            <td><div className={parseInt(rowData.current_qty) ? "badge badge-outline badge-base-100 text-lg" : "badge badge-error text-lg"}>{rowData.current_qty}</div></td>
            <td><div className="badge badge-outline badge-base-100 text-lg">${rowData.total_package_price}</div></td>
            <td><div className="badge badge-outline badge-base-100 text-lg">${rowData.total_sold_price}</div></td>
            {
                !noActions ?
                    <td>
                        <div className="flex">
                            {/*<button
                                onClick={() => setShowUpdate(true)}
                                className="btn btn-square btn-sm btn-success mr-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                </svg>
                            </button>*/}
                            <button
                                onClick={() => viewPedimento(rowData)}
                                className="btn btn-square btn-sm btn-info mr-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                                    <path strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                            </button>
                            <button
                                disabled={!(isAdmin.userImmexRole === 'admin')}
                                onClick={() => setShowDelete(true)}
                                className="btn btn-square btn-sm btn-error ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                </svg>
                            </button>
                        </div>

                    </td> :
                    null
            }

            <DeleteCard
                show={showDelete}
                handleForm={setShowDelete}
                id={rowData.pedimento_id}
                dashboardRequest={dashboardRequest}
                name="Pedimento" />
        </tr>
    );
}