import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import BarImmexPartidas from "../../components/ui/BarImmexPartidas";
import ImmexPartidaRow from "../../components/tables/ImmexPartidaRow";

import { getUserData } from "../../utils/utils";
import TableOrGrid from "../../components/tables/TableOrGrid";

export default function ImmexPartidas() {
    const [partnumber, setPartnumber] = useState("");

    const partnumbersTable = useFetch(`${process.env.REACT_APP_API_URL}get_immex_partidas`, { partnumber: partnumber });

    const userInfo = getUserData();

    useEffect(() => {
        partnumbersTable.handleRequest();
    }, [])
    return (
        <div className="w-full">
            <BarImmexPartidas refreshPage={partnumbersTable.handleRequest}
                setPartnumber={setPartnumber}
                partnumber={partnumber} />
            <TableOrGrid
                loading={partnumbersTable.loading}
                isGrid={false}
                data={partnumbersTable.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={partnumbersTable.handleRequest}
                TableRow={ImmexPartidaRow}
                TableColumns={<tr>
                    <th>Image</th>
                    <th>PartNumber</th>
                    <th>Pedimento</th>
                    <th>Location</th>
                    <th>Description</th>
                    <th>Metric Unit</th>
                    <th>JP ID</th>
                    <th>Audit</th>
                    <th>Discounts</th>
                    <th>Discount Qty</th>
                    <th>Package Qty</th>
                    <th>Stock Qty</th>
                    <th>Unit Price</th>
                    <th></th>
                </tr>} />

        </div>
    );
}