import { useState } from "react";
import PartViewer from "../form/PartViewer";
import ImmexPedimentoPartnumberAudit from "../form/ImmerPedimentoPartnumberAudit";

const NABadge = <div className="badge badge-neutral text-lg">N/A</div>

export default function ImmexPedimentoPartnumberRow(props) {
    const { rowData, isAdmin } = props;
    const [showImage, setShowImage] = useState(false);
    const [showAudit, setShowAudit] = useState(false);
    return (
        <tr className="hover">
            <td>
                <button
                    disabled={rowData.file_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold">{rowData.partnumber}</div></td>
            <td>{!rowData.location ? NABadge : <div className="badge badge-outline badge-primary">{rowData.location}</div>}</td>
            <td><div className="bg-gray-900 rounded-lg p-2 text-white">{rowData.description}</div></td>
            <td><div className="badge badge-outline badge-primary">{rowData.metric_unit}</div></td>

            <td><div className="badge badge-outline badge-secondary">{rowData.hts}</div></td>
            <td>{!rowData.jp_id ? NABadge : <div className="badge badge-outline badge-base-100">{rowData.jp_id}</div>}</td>
            <td>{!rowData.audit_date ? NABadge : <div className="badge badge-outline badge-base-100">{rowData.audit_date.slice(0, 10)}</div>}</td>
            <td><div className="badge badge-outline badge-base-100">{rowData.package_qty}</div></td>
            <td><div className="badge badge-outline badge-base-100">{rowData.current_qty}</div></td>
            <td><div className="badge badge-outline badge-base-100">${rowData.unit_cost}</div></td>
            <td>
                <div className="flex">
                    <button
                        disabled={!(isAdmin.userImmexRole === 'admin')}
                        onClick={()=>setShowAudit(true)}
                        className={rowData.audit_date ? "btn btn-square btn-sm btn-success mr-2" : "btn btn-square btn-sm btn-error mr-2"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                        </svg>
                    </button>
                </div>

            </td>
            {
                showImage ?
                    <PartViewer
                        handleForm={setShowImage}
                        id={rowData.immex_partnumber_id}
                        url="get_partnumber_image_immex" /> :
                    null
            }
            {
                showAudit ?
                    <ImmexPedimentoPartnumberAudit 
                        handleForm={setShowAudit}
                        itemData={rowData}
                        {...props}/> :
                    null
            }
        </tr>
    );
}