// src/DatePicker.js
import React, { useState } from 'react';
import { format, addMonths, subMonths, startOfWeek, 
    addDays, startOfMonth, endOfMonth, 
    endOfWeek, isSameDay, isSameMonth } from 'date-fns';

import { dateFormat } from '../../utils/constants';

export default function DatePicker(props) {
    const { title, selectedDate, setSelectDate} = props;
    const [currentMonth, setCurrentMonth] = useState(selectedDate);
    const header = () => {
        return (
            <div className="flex justify-between items-center">
                    <button
                        type="button"
                        onClick={prevMonth}
                        className="p-1 rounded-full hover:bg-gray-200">
                        &#10094;
                    </button>

                <span className="text-xl font-semibold">{format(currentMonth, dateFormat)}</span>
                    <button
                        type="button"
                        onClick={nextMonth}
                        className="p-1 rounded-full hover:bg-gray-200">
                        &#10095;
                    </button> 
            </div>
        );
    };

    const daysOfWeek = () => {
        const days = [];
        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="text-center" key={i}>
                    {format(addDays(startDate, i), 'EEE')}
                </div>
            );
        }
        return <div className="grid grid-cols-7 mt-2">{days}</div>;
    };

    const cells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, "d");
                const cloneDay = day;
                days.push(
                    <div
                        className={`p-2 text-center rounded-lg cursor-pointer hover:bg-gray-200 ${!isSameMonth(day, monthStart) ? "text-gray-400" : ""
                            } ${isSameDay(day, selectedDate) ? "bg-blue-500 text-white" : ""}`}
                        key={day}
                        onClick={() => onDateClick(cloneDay)}
                    >
                        {formattedDate}
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="grid grid-cols-7" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="">{rows}</div>;
    };
    const onDateClick = (day) => {
        setSelectDate(day);
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="form-control mb-6">
            <label for="large-input" class="block mb-2 text-sm font-medium">{title}</label>
            <div className="w-full p-4 bg-base-100 border rounded-lg shadow-lg">
                {header()}
                {daysOfWeek()}
                {cells()}
            </div>
        </div>

    );
};

