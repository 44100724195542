import { format } from 'date-fns';
import { dateFormat } from '../../utils/constants';
export const propColor={
    "user_name":"badge-primary",
    "datetime":"badge-primary",
    "from":"badge-primary",
    "to":"badge-primary",
}
export default function BadgeFilter(props) {
    const { values, handleForm, loading, skipList=["user_id"] } = props;
    const skipKeysHandle = (data) =>{
        const dataAfterSkip = Object.entries(data).filter(([key, value], index) => !skipList.includes(key))
        return dataAfterSkip
    }
    return (
        <div className="w-full bg-base-100 rounded-xl mt-6 px-4 shadow">
            <div className="flex flex-wrap pt-4">
                <button
                    onClick={() => handleForm(true)}
                    className="btn mr-3 mb-4 btn-xs btn-neutral">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" 
                        fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" 
                                strokeWidth="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z" /></svg>
                    Filter
                </button>

                {
                    skipKeysHandle(values).map(([key, value], index) =>
                            <div key={index} className={`badge ${propColor[key]} badge-lg mr-3 mb-4 text-lg badge-outline`}>
                                {`${key.replace("_", " ")}: ${key === "datetime" ? format(value, dateFormat) : value}`}
                            </div>)
                }
            </div>
            {
                loading && 
                    (<progress className="progress w-full progress-info mb-4"></progress>)
            }
        </div>

    );
}