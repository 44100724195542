

export default function HeadStatsImmexPedimento({data}) {
    const filterByAudit = data.filter((rowData)=>parseInt(rowData.total_audit) === parseInt(rowData.partidas))
    const filterByExpired = data.filter((rowData)=>rowData.status==='red')
    const filterByWarning = data.filter((rowData)=>rowData.status==='amber')
    return (
        <div className="lg:flex lg:justify-center mt-2 w-full">
            <div className="grid lg:grid-flow-col lg:auto-cols-auto gap-4 grid-flow-row auto-rows-max">
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Pedimentos</div>
                    <div className="stat-value text-blue-400 text-2xl pt-2">{data.length}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Audits</div>
                    <div className="stat-value text-green-400 text-2xl pt-2">{filterByAudit.length}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-yellow-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Export Warning</div>
                    <div className="stat-value text-yellow-400 text-2xl pt-2">{filterByWarning.length}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-5-4v4h4V3h-4Z"></path></svg>
                    </div>
                    <div className="stat-title">Export Alarm</div>
                    <div className="stat-value text-red-400 text-2xl pt-2">{filterByExpired.length}</div>
                </div>
            </div>

        </div>
    );
}