import {
	Route,
	Routes,
	createBrowserRouter,
	createRoutesFromElements,
	defer,
} from "react-router-dom";

import { BrowserRouter } from "react-router-dom";

import LoginPage from "./pages/Login";
import SignUp from "./pages/SignUp";
import HomePage from "./pages/Home";
import Error404 from "./pages/Error404";
import ScheduleAdmin from "./pages/schedule/AdminView";
import UserView from "./pages/schedule/UserView";
import NewTicketView from "./pages/tickets/newTicketView";
import TicketsAdminView from "./pages/tickets/AdminView";
import Ticket from "./components/ui/Ticket";
import TicketsDashboard from "./pages/tickets/TicketsDashboard";
import TicketsUsersTable from "./pages/tickets/TicketsUsersTable";
import TicketsUserView from "./pages/tickets/UserView";
import TicketUser from "./components/ui/TicketUser";

import UsersViewer from "./pages/users_viwer/UsersViewer";
import BudgetUser from "./layouts/BudgetUser";
import InvoiceUser from "./layouts/InvoiceUser";
import UserHome from "./layouts/UserHome";

import LcuPartnumbers from "./pages/warehouse/LcuPartnumbers";
import LcuBom from "./pages/warehouse/LcuBom";
import LcuDelivery from "./pages/warehouse/LcuDelivery";
import LcuCPU from "./pages/warehouse/LcuCPU";
import LcuBox from "./pages/warehouse/LcuBox";
import ImmexPartnumbers from "./pages/immex/ImmexPartnumbers";
import ImmexPedimento from "./pages/immex/ImmexPedimento";
import ImmexCheckout from "./pages/immex/ImmexCheckout";
import ImmexLocation from "./pages/immex/ImmexLocation";
import ImmexPartidas from "./pages/immex/ImmexPartidas";

//import { HomeLayout } from "./components/HomeLayout";
//import "./styles.css";
import { AuthLayout } from "./components/AuthLayout";
import RequireAuth from "./components/RequireAuth";
import Menu from "./layouts/Menu";
import ProfileView from "./pages/profile/profileView";
import Test from "./pages/test";

import { io } from "socket.io-client";
import ReportsCharts from "./pages/charts/ReportsCharts";
import JigsView from "./pages/jigs/JigsView";

import PurchaseOrders from "./pages/po/PurchaseOrders";
import ClientsTable from "./pages/utils/clientsTable";
import TycTable from "./pages/utils/TycTable";
import JigsTable from "./pages/jigs/JigsTable";
import QuotesTable from "./pages/quotes/QuotesTable";
import CreateQuote from "./pages/quotes/CreateQuote";


const ROLES = {
	user: "user",
	client: "client",
	Admin: 5150,
};

/*const socket = io.connect(process.env.REACT_APP_API_SOCKET, {
	cors: { origin: "*" },
});
console.log("socket ", socket);*/
const socket=null;

function App() {
	return (
		<Routes>
				{/* public routes */}
				<Route path="/" element={<LoginPage />} />
				<Route path="/signup" element={<SignUp />} />

				{/* private routes */}
				<Route element={<RequireAuth allowedRoles={[ROLES.user]} />}>
					<Route path="/dashboard" element={<HomePage />} />
					<Route path="/schedule/admin" element={<ScheduleAdmin />} />
					<Route path="/schedule/user" element={<UserView />} />
					<Route path="/tickets/new" element={<NewTicketView />} />
					<Route path="/profile" element={<ProfileView />} />
					<Route path="/test" element={<Test />} />
					<Route path="/tickets/admin" element={<TicketsAdminView socket={socket} />} />
					<Route path="/tickets/admin/ticket" element={<Ticket />} />
					<Route path="/tickets/dashboard" element={<TicketsDashboard />} />
					<Route path="/tickets/usersTable" element={<TicketsUsersTable />} />
					<Route path="/tickets/user" element={<TicketsAdminView socket={socket} />} />
					<Route path="/tickets/user/ticket" element={<TicketUser />} />
					<Route path="/charts/reports" element={<ReportsCharts />} />

					<Route path="/po" element={<PurchaseOrders />} />

					<Route path="/user/home" element={<UserHome />} />
					<Route path="/user/budget" element={<BudgetUser />} />
					<Route path="/user/expense" element={<InvoiceUser />} />
					<Route path="/user/viewer" element={<UsersViewer />} />

					<Route path="/jigs" element={<JigsView />} />
					<Route path="/jigsTable" element={<JigsTable />} />

					<Route path="/warehouse/partnumber" element={<LcuPartnumbers />} />
					<Route path="/warehouse/bom" element={<LcuBom />} />
					<Route path="/warehouse/delivery" element={<LcuDelivery />} />
					<Route path="/warehouse/cpu" element={<LcuCPU />} />
					<Route path="/warehouse/lcubox" element={<LcuBox />} />

					<Route path="/immex/pedimento" element={<ImmexPedimento />} />
					<Route path="/immex/partnumber" element={<ImmexPartnumbers />} />
					<Route path="/immex/checkout" element={<ImmexCheckout />} />
					<Route path="/immex/location" element={<ImmexLocation />} />
					<Route path="/immex/partida" element={<ImmexPartidas />} />

					<Route path="/utils/clients" element={<ClientsTable />} />
					<Route path="/utils/tyc" element={<TycTable />} />

					<Route path="/quotes" element={<QuotesTable />} />
					<Route path="/quotes/create" element={<CreateQuote />} />

				</Route>

				{/* catch all */}
				<Route path="*" element={<LoginPage />} />
		</Routes>
	);
}

export default App;

/*<Route element={<RequireAuth allowedRoles={[ROLES.client]} />}>
					<Route path="editor" element={<Editor />} />
				</Route>*/

/*const getUserData = () =>
	new Promise(resolve =>
		setTimeout(() => {
			const user = window.localStorage.getItem("user");
			console.log(user);
			const user2 = { user: "dsa" };
			resolve(user2);
		}, 3000)
	);

// for error
// const getUserData = () =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       reject("Error");
//     }, 3000)
//   );

export const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			element={<AuthLayout />}
			loader={() => defer({ userPromise: getUserData() })}
		>
			<Route path="/" element={<LoginPage />} />

			<Route path="/dashboard" element={<ProtectedLayout />}>
				<Route index element={<HomePage />} />
				<Route path="profile" element={<HomePage />} />
			</Route>

			<Route path="*" element={<Error404 />} />
		</Route>
	)
);*/
