import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { getUserData } from "../../utils/utils";
import IdSelector from "../../components/inputs/IdSelector";
import AddAndUpdateBomPart from "../../components/form/AddAndUpdateBomPart";
import TableOrGrid from "../../components/tables/TableOrGrid";
import LcuBomRow from "../../components/tables/LcuBomRow";
import BarLcuBom from "../../components/ui/BarLcuBom";

const getBomCost = (data) => {
    const bomCost = data.reduce((total, row) => {
        return total + parseFloat(row.bom_cost)
    }, 0)

    return bomCost;
}

const minBomVsStock = (data) => {
    if (data.length) {
        const extractBomVsStock = data.map((row) => parseInt(row.bom_units));
        return Math.min(...extractBomVsStock);
    }
    else {
        return 0;
    }
}

export default function LcuBom() {
    const [bomName, setBomName] = useState(1);
    const [showEdit, setShowEdit] = useState(false);

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}get_bom_partnumbers`, { bom_id: bomName })
    

    const userInfo = getUserData();

    useEffect(() => {
        dataTable.handleRequest()
    }, [bomName])


    return (
        <div className="w-full">
            <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                    <div className="stat-title">Bom Cost</div>
                    <div className="stat-value">${getBomCost(dataTable.data)}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Min Bom vs Stock</div>
                    <div className="stat-value">{minBomVsStock(dataTable.data)}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Parts Numbers</div>
                    <div className="stat-value">{dataTable.data.length}</div>
                </div>
            </div>

            <BarLcuBom
                refreshPage={dataTable.handleRequest}
                loading={dataTable.loading}
                bomName={bomName}
                setBomName={setBomName}
                userInfo={userInfo} />
            <TableOrGrid
                loading={dataTable.loading}
                isGrid={false}
                data={dataTable.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={dataTable.handleRequest}
                TableRow={LcuBomRow}
                TableColumns={<tr>
                    <th>Image</th>
                    <th>PartNumber</th>
                    <th>Description</th>
                    <th>Vendor</th>
                    <th>Manufacturer</th>
                    <th>Category</th>
                    <th>Link</th>
                    <th>Location</th>
                    <th>Stock Qty</th>
                    <th>Bom Qty</th>
                    <th>Bom vs Stock</th>
                    <th>Stock Cost</th>
                    <th>Bom Cost</th>
                    <th>Unit Cost</th>
                    <th></th>
                </tr>} />
            {
                showEdit ?
                    <AddAndUpdateBomPart
                        handleForm={setShowEdit}
                        dashboardRequest={dataTable.handleRequest}
                        url="add_partnumber_to_bom"
                        startBody={{
                            bom_id: bomName,
                            partnumber_id: 1,
                            qty: 0,
                        }} /> : null
            }
        </div>
    )
}