import { useState } from "react";

export const handleTotalPages = (totalRows, rowsPerPage)=>{
    return Math.ceil(totalRows / rowsPerPage);
}

export const handleRowsStartEnd = (currentPage, rowsPerPage, dataLen)=>{
    const startIndex = !(currentPage - 1) ? 0 : (currentPage - 1) * rowsPerPage;
    const endIndex = currentPage === handleTotalPages(dataLen, rowsPerPage) ? dataLen : currentPage * rowsPerPage;
    return {startIndex, endIndex};
}

const handlePagination = (totalPages, setCurrentPage) => {

    return {
      prev: () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
      },
      next: () => {
        setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
      }
    };
  };

export default function GridTableControl({ currentPage, setCurrentPage, totalRows = 300, rowsPerPage }) {
    const totalPages = handleTotalPages(totalRows, rowsPerPage);
    const { prev, next } = handlePagination(totalPages, setCurrentPage);
    return (
        <>
            {   totalPages>1?
                <div className='w-full mt-4'>
                    <div className="navbar bg-base-100 mb-4 rounded-xl flex-none">
                        
                            <button
                                onClick={prev}
                                disabled={currentPage === 1}
                                className="join-item btn btn-success">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 16.881V7.119a1 1 0 0 0-1.636-.772l-5.927 4.881a1 1 0 0 0 0 1.544l5.927 4.88a1 1 0 0 0 1.636-.77Z" />
                                </svg>
                            </button>
                            
                            <div className="flex-grow" />
                            <button className="join-item btn btn-success">{`Page ${currentPage}/${totalPages}`}</button>
                            <div className="flex-grow" />
                            <button 
                                onClick={next}
                                disabled={currentPage === totalPages}
                                className="join-item btn btn-success">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16.881V7.119a1 1 0 0 1 1.636-.772l5.927 4.881a1 1 0 0 1 0 1.544l-5.927 4.88A1 1 0 0 1 8 16.882Z" />
                                </svg>
                            </button>
                    </div>
                </div>:null
            }
        </>
    );
}