import { useState, useEffect } from "react";
import GridTableControl, {  handleRowsStartEnd } from "./GridTableControl";
import GridCards from "./GridCards";
import TableRows from "./TableRows";

const rowsPerPage = 30;

export default function TableOrGrid(props) {
    const { GridCard, TableRow, data, isAdmin, dashboardRequest, isGrid, TableColumns, loading, pagerTop=true, pagerButton=true } = props;
    const [currentPage, setCurrentPage] = useState(1);

    const { startIndex, endIndex } = handleRowsStartEnd(currentPage, rowsPerPage, data.length);
    const limitData = data.slice(startIndex, endIndex);

    useEffect(()=>{
        setCurrentPage(1);
    },[loading])

    return (
        <div className="w-full mt-4">
            {
                pagerTop?
                <GridTableControl
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                totalRows={data.length} />:null
            }
            {
                loading?
                <div className="bg-base-100 p-4 rounded-xl mb-4">
                    <progress className="progress progress-warning w-full "></progress>
                </div>:null
            }
            {
                isGrid ?
                    <GridCards
                        GridCard={GridCard}
                        cardsData={limitData}
                        isAdmin={isAdmin}
                        dashboardRequest={dashboardRequest} 
                        {...props}/> :
                    <TableRows
                        TableRow={TableRow}
                        rowsData={limitData}
                        isAdmin={isAdmin}
                        dashboardRequest={dashboardRequest}
                        Columns={TableColumns} 
                        {...props}/>
            }
            {
                pagerButton?
                <GridTableControl
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                totalRows={data.length} />:null
            }


        </div>
    );
}