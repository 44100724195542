import { useState } from "react";
import CleanAuditPedimentoCard from "../form/CleanAuditPedimentoCard";

export default function BarImmexPedimentosPartnumber(props) {
    const { refreshPage, partnumber, setPedimentoSelected, setPartnumber, pedimentoId, userInfo } = props;
    const [showCleanAudit, setShowCleanAudit] = useState(false);
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            refreshPage();
        }
    };
    return (
        <div className="navbar bg-base-100 my-4 rounded-xl ">
            <div className="flex-1">
                {
                    partnumber ?
                        <div
                            onClick={() => setPartnumber("")}
                            className="badge badge-outline gap-2 badge-lg">
                            {`Partnumber=${partnumber}`}
                        </div> :
                        null
                }
            </div>
            <div className="navbar-end">
                <label className="input input-bordered flex items-center gap-2">
                    <input
                        onKeyDown={handleKeyDown}
                        value={partnumber}
                        onChange={(e) => setPartnumber(e.target.value)}
                        type="text" className="grow" placeholder="Partnumber" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd" />
                    </svg>
                </label>
                <button
                    onClick={() => refreshPage()}
                    className="btn btn-square btn-info ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                    </svg>
                </button>
                <button
                    disabled={!(userInfo.userImmexRole === 'admin')}
                    onClick={() => setShowCleanAudit(true)}
                    className="btn btn-square btn-error ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.5713 5h7v9h-7m-6.00001-4-3 4.5m3-4.5v5m0-5h3.00001m0 0h5m-5 0v5m-3.00001 0h3.00001m-3.00001 0v5m3.00001-5v5m6-6 2.5 6m-3-6-2.5 6m-3-14.5c0 .82843-.67158 1.5-1.50001 1.5-.82843 0-1.5-.67157-1.5-1.5s.67157-1.5 1.5-1.5 1.50001.67157 1.50001 1.5Z" />
                    </svg>
                </button>
                <button
                    onClick={() => setPedimentoSelected(null)}
                    className="btn btn-square btn-warning ml-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </button>
            </div>
            <CleanAuditPedimentoCard
                show={showCleanAudit}
                handleForm={setShowCleanAudit}
                id={pedimentoId}
                dashboardRequest={refreshPage} />
        </div>
    );
}