import { useState, useEffect } from "react";
import MonthPicker from "../components/inputs/MonthPicker";
import HeadStatsIndividual from "../components/ui/HeadStatsIndividual";
import BadgeFilter from "../components/ui/BadgeFilters";
import FilterInvoiceSearch from "../components/form/FilterInvoiceSearch";
import InvoiceCard from "../components/tables/InvoiceCard";
import AddAndUpdateInvoice from "../components/form/AddAndUpdateInvoice";
import AlertMain from "../components/ui/AlertMain";
import useFetch from "../hooks/useFetch";
import GridTableHead from "../components/tables/GridTableHead";
import TableOrGrid from "../components/tables/TableOrGrid";
import InvoiceRow from "../components/tables/InvoiceRow";

import { getUserData, searchBody, monthDateWithThreshold, HandleIsMobile } from "../utils/utils";
import { dateFormatDB } from "../utils/constants";
import { format } from "date-fns";

export const bodyToAddNewExpense = (id) => {
    return {
        id: id,
        principal: "",
        client_name: "",
        expense_type: "",
        datetime_obj: new Date(),
        datetime: format(new Date(), dateFormatDB),
        amount: 0,
        concept: "",
        files: []
    }
}

export default function InvoiceUser() {
    const [showSearch, setShowSearch] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [isGrid, setIsGrid] = useState(false);

    const dayThreshold = 25;
    const userInfo = getUserData();

    const { from, to } = monthDateWithThreshold(new Date(), dayThreshold)
    const [filterValues, setFilterValues] = useState(
        searchBody(userInfo.isAdmin ? 0 : userInfo.userId,
            userInfo.isAdmin ? "ALL" : userInfo.userName, from, to)
    );

    const { data, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}expense_cards`,
        filterValues
    )
    const checkReport = useFetch(`${process.env.REACT_APP_API_URL}check_expense_report_user`,
        filterValues
    )
    useEffect(() => {
        checkReport.handleRequest()
        handleRequest();
    }, [filterValues])
    const isMobile = HandleIsMobile();

    const dateHandle = (dt) => {
        const { from, to } = monthDateWithThreshold(dt, dayThreshold)
        setFilterValues({
            ...filterValues,
            "datetime": dt,
            "from": from,
            "to": to
        })
    }
    return (
        <div className="p-4">
            <HeadStatsIndividual
                data={data}
                dayThreshold={dayThreshold}
                name="Expenses" />
            <BadgeFilter
                values={filterValues}
                handleForm={setShowSearch}
                loading={loading}
                skipList={["user_id", "budget_type"]} />

            <AlertMain error={error} text={"Error! expense_cards."} />
            <GridTableHead title="Expenses"
                addClickHandle={setShowAdd}
                filterValues={filterValues}
                dashboardRequest={handleRequest}
                cardsData={data}
                disableReport={false}
                diableAdd={checkReport.data.length}
                setIsGrid={setIsGrid}
                isGrid={isGrid} />
            <MonthPicker
                title="Select Month"
                setSelectMonth={dateHandle}
                selectMonth={filterValues.datetime}
                hiddenTitle={true}
                style="form-control mt-4" />
            <TableOrGrid
                loading={loading}
                isGrid={isMobile}
                data={data}
                GridCard={InvoiceCard}
                isAdmin={userInfo.isAdmin}
                dashboardRequest={handleRequest}
                TableRow={InvoiceRow}
                TableColumns={<tr>
                    <th></th>
                    <th>ID</th>
                    <th>Principal</th>
                    <th>Client</th>
                    <th>User Name</th>
                    <th>Type</th>
                    <th>Concept</th>
                    <th>Denial Msg</th>
                    <th>Money</th>
                    <th>Date</th>
                    <th>City</th>
                    <th>Docs Qty</th>
                    <th>Actions</th>
                    <th></th>
                </tr>} />

            <AddAndUpdateInvoice
                show={showAdd}
                handleForm={setShowAdd}
                userName={userInfo.userName}
                dashboardRequest={handleRequest}
                startBody={bodyToAddNewExpense(userInfo.userId)}
                url={'add_expense_files'}
                title="Add Expense"
                isAdmin={userInfo.isAdmin} />
            <FilterInvoiceSearch
                show={showSearch}
                handleForm={setShowSearch}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                isAdmin={userInfo.isAdmin}
                dayThreshold={dayThreshold}
            />
        </div>
    )
}
