import { useState } from "react";
import PartViewer from "../form/PartViewer";
import useFetchClick from "../../hooks/useFetchClick";

export default function ImmexShopingCartRow({ rowData, dashboardRequest }) {
    const [showImage, setShowImage] = useState(false);
    const delPart = useFetchClick(
        `${process.env.REACT_APP_API_URL}delete_immexpartcheckout`,
        { "id": rowData.immex_checkout_items_id },
        dashboardRequest)
    return (
        <tr className="hover">
            <td>
                <button
                    disabled={rowData.file_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold">{rowData.partnumber}</div></td>
            <td><div className="badge badge-outline">{rowData.qty}</div></td>
            <td>
                <div className="flex">
                    <button
                        onClick={() => delPart.handleRequest()}
                        className="btn btn-square btn-outline btn-sm btn-error ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                    {
                        showImage ?
                            <PartViewer
                                handleForm={setShowImage}
                                id={rowData.immex_partnumber_id}
                                url="get_partnumber_image_immex" /> :
                            null
                    }
                </div>

            </td>
        </tr>
    );
}