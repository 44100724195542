import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import SelectClients from "../../components/form/selectClients";
import PoTable from "../../components/tables/poTable";
import TanStackTable from "../../components/TanStackTable";
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
import VacationList from "../../components/ui/VacationList";
import EditJigForm from "../../components/form/EditJigForm";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
	faTicket,
	faTable,
	faChartSimple,
	faCartShopping,
	faMoneyCheck,
	faFileInvoiceDollar,
	faUserGroup,
	faPenToSquare,
	faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import AddQuoteForm from "../../components/form/AddQuoteForm";
import QuoteForm from "../../components/form/QuoteForm";

const moment = require("moment");

function CreateQuote(props) {
	const { changeTicket, userIdSelected, refresh, tickets } = props;
	
	

	return (
		<div className="overflow-y-hiden bg-base-100 card shadow-lg p-6">
            <QuoteForm></QuoteForm>
        </div>
	);
}

export default CreateQuote;
