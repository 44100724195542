export default function ImmexExcelPedimentoRow({ rowData }) {
    return (
        <tr className="hover">
            <td>{rowData.partnumber}</td>
            <td>{rowData.description}</td>
            <td>{rowData.metric_unit}</td>
            <td>{rowData.qty}</td>
            <td>{rowData.unit_price}</td>
            <td>{rowData.hts}</td>
            <td>{rowData.jp_id}</td>
        </tr>
    );
}