import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import SelectClients from "../../components/form/selectClients";
import PoTable from "../../components/tables/poTable";
import TanStackTable from "../../components/TanStackTable";
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
import VacationList from "../../components/ui/VacationList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
	faTicket,
	faTable,
	faChartSimple,
	faCartShopping,
	faMoneyCheck,
	faFileInvoiceDollar,
	faUserGroup,
	faPenToSquare,
	faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import EditJigForm from "../../components/form/EditJigForm";
import EditJigItemForm from "../../components/form/EditJigItemForm";

const moment = require("moment");

function JigsTable(props) {
	const { changeTicket, userIdSelected, refresh, tickets } = props;
	
	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "jigs";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [poIdSelected, setPoIdSelected] = useState(0);
	const [selectedRow, setSelectedRow] = useState([]);

	const [hoursAfterSub, setHoursAfterSub] = useState(0.0);
	const [costAfterSub, setCostAfterSub] = useState(0.0);

	var searchedVal = ""
	var search = ""

	const MySwal = withReactContent(Swal)


  const editSwal = async (e) => {
	const row = JSON.parse(e.target.dataset.row);
	const type = e.target.dataset.type;
		console.log(row);

		if (type == "jig") {
		const { value: formValues } = await MySwal.fire({
			title: "Edit Jig",
			html: <EditJigForm row={row} />,
			focusConfirm: false,
			preConfirm: () => {
				return {
					id: document.getElementById("id").value,
					row: document.getElementById("row").value,
					position: document.getElementById("position").value,
					quantity: document.getElementById("quantity").value,
					name: document.getElementById("name").value,
					description: document.getElementById("description").value,
					location: document.getElementById("location").value,
				};
			},
		});
		if (formValues) {
			handleEditJig(formValues, type);
		}
		} else {
const { value: formValues } = await MySwal.fire({
	title: "Edit Jig Item",
	html: <EditJigItemForm row={row} />,
	focusConfirm: false,
	preConfirm: () => {
		return {
	id: document.getElementById("id").value,
	quantity: document.getElementById("quantity").value,
	description: document.getElementById("description").value,
};
	},
});
if (formValues) {
	handleEditJig(formValues, type);
}
		}
  }

  const deleteSwal = async (e) => {
	const type = e.target.dataset.type;
	const id = e.target.dataset.id;
		console.log(id);
    if (id) {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		  }).then((result) => {
			if (result.isConfirmed) {
				//alert("true");
				handleDeleteJig(id, type);
			}
		  });
	}
  }

  const handleDeleteJig = async (id, type) => {
	//event.preventDefault();

	if (true) {
		var endpoint = process.env.REACT_APP_API_URL + "jigs/" + id;
		if (type == "item") {
			endpoint = process.env.REACT_APP_API_URL + "jigsItems/" + id;
		}
		try {
			const response = await axios.delete(
				endpoint,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			console.log(response.data);
			console.log(true);
			toast.success(response.data.message);
			setRefetch(!refetch);
		} catch (error) {
			console.log(error);
			toast.error(error);
		}
	} else {
		console.log("error");
	}

	//console.log(clientId, localStorage.getItem("userId"));

	//const userEmail = event.target.userEmail.value;
};

const handleEditJig = async (row, type) => {
	//event.preventDefault();

	if (true) {
		var endpoint = process.env.REACT_APP_API_URL + "jigs";
		if (type == "item") {
			endpoint = process.env.REACT_APP_API_URL + "jigsItems";
		}
		try {
			const response = await axios.put(
				endpoint,
				row,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			console.log(response.data);
			console.log(true);
			toast.success(response.data.message);
			setRefetch(!refetch);
		} catch (error) {
			console.log(error);
			toast.error(error);
		}
	} else {
		console.log("error");
	}

	//console.log(clientId, localStorage.getItem("userId"));

	//const userEmail = event.target.userEmail.value;
};
	
	var curr = new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);

	const [clientSelected, setClientSelected] = useState();
	const changeTab = index => {
		console.log(index);
		setClientSelected(index.id);
	};

	const [test, setTest] = useState(null);

	const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    var searchedVal = e.target.value.toLowerCase();
	search = searchedVal
	var newData = data.filter((row) =>
		// note that I've incorporated the searchedVal length check here
		!searchedVal.length || row.po
		  .toString()
		  .toLowerCase()
		  .includes(searchedVal.toString().toLowerCase()) 
	  )

	  setData(newData)
    //setInputText(lowerCase);
  };

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	const columns = [
	{
		name: "id",
		selector: row => row.id,
		sortable: true,
	},
	{
		name: "row",
		selector: row => row.row,
		sortable: true,
	},
	{
		name: "position",
		selector: row => row.position,
		sortable: true,
	},
	{
		name: "quantity",
		selector: row => row.quantity,
		sortable: true,
	},
	{
		name: "name",
		selector: row => row.name,
		sortable: true,
	},
	{
		name: "description",
		selector: row => row.description,
		sortable: true,
	},
	{
		name: "location",
		selector: row => row.location,
		sortable: true,
	},
	{
		name: "createdAt",
		selector: row => row.createdAt,
		sortable: true,
	},
	{
		name: "",
		button: true,
		cell: row => (
			<div className="flex justify-center items-center">
				<div
					className="btn btn-xs btn-info mr-2"
					data-id={row.id}
					data-type="jig"
					data-row={JSON.stringify(row)}
					onClick={editSwal}
				>
					Edit
				</div>
				<div
					className="btn btn-xs btn-error"
					data-id={row.id}
					data-type="jig"
					data-row={JSON.stringify(row)}
					onClick={deleteSwal}
				>
					Del
				</div>
			</div>
		),
	},
];

	//<FontAwesomeIcon icon={faPenToSquare} className="mr-2 text-xl text-sky-600 cursor-pointer" data-id={row.id} data-row={row} onClick={handleEdit}/>
	const handleDelete = (e) => {
		e.stopPropagation()
		const id = e.target.dataset.id;
		console.log(id);
		
	};

	const handleEdit = (e) => {
		e.stopPropagation()
		const id = e.target;
		console.log(id);
		
	};

	const ExpandedComponent = ({ data }) => (
	<div className="p-6">
		<p>row: {data.row}</p>
		<p>position: {data.position}</p>
		<p>quantity: {data.quantity}</p>
		<p>name: {data.name}</p>
		<p>description: {data.description}</p>
		<p>location: {data.location}</p>
		<p>creator: {data.user.name}</p>
		<p>date: {data.createdAt}</p>

		<div className="">
			{data.jigsItems.map(item => (
				<div className="my-4 p-4 flex border">
					<div className="">
						<p>quantity: {item.quantity}</p>
						<p>name: {item.description}</p>
						<p>date: {item.date}</p>
					</div>

					<div className="ml-4 flex justify-center items-center flex-wrap">
						<div
							className="btn btn-xs btn-info w-full"
							data-id={item.id}
							data-type="item"
							data-row={JSON.stringify(item)}
							onClick={editSwal}
						>
							Edit
						</div>
						<div
							className="btn btn-xs btn-error w-full"
							data-id={item.id}
							data-type="item"
							onClick={deleteSwal}
						>
							Del
						</div>
					</div>
				</div>
			))}
		</div>
	</div>
);

	const handleSearch = (e) => {
		searchedVal = e.target.value.toLowerCase();
		var filteredItems = data.filter(
			item => item.po && item.po.toLowerCase().includes(searchedVal.toLowerCase()),
		);

		console.log("filteresItems ", filteredItems);
		console.log("searchedVal ", searchedVal);
		
	
		setData(filteredItems);
	  };

	  function convertArrayOfObjectsToCSV(array) {
		let result;
	
		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(data[0]);
	
		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;
	
		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
	
				result += item[key];
				
				ctr++;
			});
			result += lineDelimiter;
		});
	
		return result;
	}
	
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
	
		const filename = 'export.csv';
	
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
	
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	
	const Export = ({ onExport }) => <button className="btn join-item btn-neutral m-4" onClick={e => onExport(e.target.value)}>Export</button>;

		const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

		const FilterComponent = ({ filterText, onFilter, onClear }) => (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Name"
					aria-label="Search Input"
					value={filterText}
					onChange={onFilter}
				/>
				<button type="button" onClick={onClear}>
					X
				</button>
			</>
		);
	
		const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	var filteredItems = null;
	if (data) {
		filteredItems = data.filter( 
			item => item.po && item.po.toLowerCase().includes(filterText.toLowerCase()),
		);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	const conditionalRowStyles = [
		/*{
			when: row => row.costLeft > 3000 ,
			style: {
				backgroundColor: 'rgba(63, 195, 128, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.calories >= 300 && row.calories < 400,
			style: {
				backgroundColor: 'rgba(248, 148, 6, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.costLeft == 0,
			style: {
				backgroundColor: 'rgba(242, 38, 19, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'not-allowed',
				},
			},
		},*/
	];

	const handleChange = ({ selectedRows }) => {
		// You can set state or dispatch with something like Redux so we can use the retrieved data
		setSelectedRow(selectedRows);

		


		
		console.log('Selected Rows: ', selectedRows);
		console.log("selected row: ", selectedRow);
		
	  };

	const handleAddPOForm = async event => {
		event.preventDefault();

		//const clientId = clientSelected;
		const poId = event.target.poId.value;
		const reportId = event.target.reportId.value;
		const concept = event.target.concept.value;
		const date = event.target.date.value;
		const hours = event.target.hours.value;
		const cost = event.target.cost.value;
		const hoursBefore = event.target.hoursBefore.value;
		const costBefore = event.target.costBefore.value;
		const hoursAfter = event.target.hoursAfter.value;
		const costAfter = event.target.costAfter.value;
		
		

		if (true) {
			const endpoint = process.env.REACT_APP_API_URL + "purchaseOrdersChanges";
			try {
				const response = await axios.post(
					endpoint,
					{
						poId: poId,
						reportId: reportId,
						concept: concept,
						date: date,
						hours: hours,
						cost: cost,
						hoursBefore: hoursBefore,
						costBefore: costBefore,
						hoursAfter: hoursAfter,
						costAfter: costAfter,
						
						userId: localStorage.getItem("userId"),
					},
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);

				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}

		//console.log(clientId, localStorage.getItem("userId"));

		//const userEmail = event.target.userEmail.value;
	};


	if (loading) 
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	return (
		<div>

			
 
			<div className="overflow-x-auto bg-base-100 card shadow-lg">
			{searchedVal != "" ?
				<DataTable
				title="Jigs Table"
				columns={columns}
				data={filteredItems}
				selectableRows
				onSelectedRowsChange={handleChange}
				expandableRows
				expandableRowsComponent={ExpandedComponent}
				pagination
				actions={actionsMemo}
				paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
				//subHeaderComponent={subHeaderComponentMemo}
			/>
			: data ? <DataTable
			title="Jigs Table"
			columns={columns}
			data={data}
			selectableRows
			onSelectedRowsChange={handleChange}
			expandableRows
			expandableRowsComponent={ExpandedComponent}
			pagination
			actions={actionsMemo}
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			//subHeaderComponent={subHeaderComponentMemo}
			conditionalRowStyles={conditionalRowStyles}
		/>
		:<></>
			}
			</div>

</div>
	);
}

export default JigsTable;
