import {useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../../components/tables/TableOrGrid";
import LcuDeliveryRow from "../../components/tables/LcuDeliveryRow";
import BarLcuDelivery from "../../components/ui/BarLcuDelivery";
import { getUserData } from "../../utils/utils";

export default function LcuDelivery() {
    const [searchLcu, setSearchLcu] = useState('');
    const userInfo= getUserData();
    const getDeliveries = useFetch(`${process.env.REACT_APP_API_URL}get_lcu_deliveries`, {
        lcuBox:searchLcu
    })
    useEffect(() => {
        getDeliveries.handleRequest();
    }, [])

    return (
        <div className="w-full max-w-screen-2xl m-auto">
            <BarLcuDelivery 
                userInfo={userInfo}
                searchLcu={searchLcu} 
                setSearchLcu={setSearchLcu}
                refreshPage={getDeliveries.handleRequest} 
                loading={getDeliveries.loading}/>
            <TableOrGrid
                loading={getDeliveries.loading}
                isGrid={false}
                data={getDeliveries.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={getDeliveries.handleRequest}
                TableRow={LcuDeliveryRow}
                TableColumns={<tr>
                    <th>Delivery ID Number</th>
                    <th>Serial Number</th>
                    <th>Client Name</th>
                    <th>Mac Address</th>
                    <th>Type</th>
                    <th>Software Version</th>
                    <th>Delivery Date</th>
                    <th>Engagement Date</th>
                    <th></th>
                </tr>} />

        </div>
    );
}