import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import ImmexLocationRow from "../../components/tables/ImmexLocationRow";
import ImmexlocationPartnumberRow from "../../components/tables/ImmexLocationPartnumberRow";
import BarImmexLocations from "../../components/ui/BarImmexLocations";
import BarImmexLocationPartnumber from "../../components/ui/BarImmexLocationPartnumber";

import { getUserData } from "../../utils/utils";
import TableOrGrid from "../../components/tables/TableOrGrid";

export default function ImmexLocation() {
    const [partnumber, setPartnumber] = useState("");
    const [location, setLocation] = useState("");
    const [locationSelected, setLocationSelected] = useState(null);

    const locationsTable = useFetch(`${process.env.REACT_APP_API_URL}get_immex_locations`, { location: location });
    
    const partnumbersTable = useFetch(`${process.env.REACT_APP_API_URL}get_immex_location_partnumbers`, 
        { 
            id: locationSelected?locationSelected.immex_location_id:0, 
            partnumber: partnumber 
        });

    const userInfo = getUserData();

    useEffect(() => {
        if (locationSelected) {
            partnumbersTable.handleRequest();
        }
        else {
            locationsTable.handleRequest();
        }
    }, [locationSelected])

    const refreshAll = () =>{
        partnumbersTable.handleRequest();
        locationsTable.handleRequest();
    }
    return (
        <div className="w-full">
            {
                !locationSelected ?
                    <>
                        <BarImmexLocations refreshPage={locationsTable.handleRequest}
                            setLocation={setLocation}
                            location={location}
                            userInfo={userInfo} />
                        <TableOrGrid
                            loading={locationsTable.loading}
                            isGrid={false}
                            data={locationsTable.data}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={locationsTable.handleRequest}
                            TableRow={ImmexLocationRow}
                            TableColumns={<tr>
                                <th>ID</th>
                                <th>Location</th>
                                <th>Partidas</th>
                                <th>Audits</th>
                                <th>Partnumbers</th>
                                <th>Pedimentos</th>
                                <th>Stock Qty</th>
                                <th>JPIDs</th>
                                <th></th>
                            </tr>}
                            setLocationSelected={setLocationSelected} />
                    </> :
                    <>
                        <BarImmexLocationPartnumber refreshPage={refreshAll}
                            setPartnumber={setPartnumber}
                            partnumber={partnumber}
                            setLocationSelected={setLocationSelected}
                            locationId={locationSelected.immex_location_id}
                            userInfo={userInfo} />
                        <TableOrGrid
                            pagerButton={false}
                            pagerTop={false}
                            loading={locationsTable.loading}
                            isGrid={false}
                            data={locationsTable.data.filter((rowlocation)=>rowlocation.immex_location_id===locationSelected.immex_location_id)}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={null}
                            TableRow={ImmexLocationRow}
                            TableColumns={<tr>
                                <th>ID</th>
                                <th>Location</th>
                                <th>Partidas</th>
                                <th>Audits</th>
                                <th>Partnumbers</th>
                                <th>Pedimentos</th>
                                <th>Stock Qty</th>
                                <th>JPIDs</th>
                                <th></th>
                            </tr>}
                            setLocationSelected={setLocationSelected}
                            noActions={true} />

                        <TableOrGrid
                            pagerButton={false}
                            loading={partnumbersTable.loading}
                            isGrid={false}
                            data={partnumbersTable.data}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={refreshAll}
                            TableRow={ImmexlocationPartnumberRow}
                            TableColumns={<tr>
                                <th>ID</th>
                                <th>Partnumber</th>
                                <th>Metric Unit</th>
                                <th>Description</th>
                                <th>Stock Qty</th>
                                <th>Unit Cost</th>
                                <th>JPID</th>
                                <th>Pedimento</th>
                                <th>Audit</th>
                                <th></th>
                            </tr>} />
                    </>
            }
        </div>
    );
}