

import AddAndUpdateLcuCpu from "../form/AddAndUpdateLcuCpu";
import DeleteCard from "../form/DeleteCard";
import { useState } from "react";
import { CellNull } from "./CellNull";
export default function LcuCpuRow({ rowData, isAdmin, dashboardRequest }) {
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    return (
        <tr className="hover">
            <td><div className="badge badge-outline badge-primary">{rowData.lcu_cpu_id}</div></td>
            <td>{!rowData.mac_address_nexim ? CellNull : <div className="badge badge-info">{rowData.mac_address_nexim}</div>}</td>
            <td>{!rowData.windows_key ? CellNull :
                <button
                    onClick={() => { navigator.clipboard.writeText(rowData.windows_key) }}
                    className="btn btn-xs btn-success">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 12a28.076 28.076 0 0 1-1.091 9M7.231 4.37a8.994 8.994 0 0 1 12.88 3.73M2.958 15S3 14.577 3 12a8.949 8.949 0 0 1 1.735-5.307m12.84 3.088A5.98 5.98 0 0 1 18 12a30 30 0 0 1-.464 6.232M6 12a6 6 0 0 1 9.352-4.974M4 21a5.964 5.964 0 0 1 1.01-3.328 5.15 5.15 0 0 0 .786-1.926m8.66 2.486a13.96 13.96 0 0 1-.962 2.683M7.5 19.336C9 17.092 9 14.845 9 12a3 3 0 1 1 6 0c0 .749 0 1.521-.031 2.311M12 12c0 3 0 6-2 9" />
                    </svg>
                    Copy Key
                </button>}

            </td>
            <td>{!rowData.datetime ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.datetime.slice(0, 10)}</div>}</td>
            <td>{!rowData.serialnumber ? CellNull :
                <div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.serialnumber}</div>}</td>
            <td>{!rowData.client_name ? CellNull : <div className="badge badge-primary">{rowData.client_name}</div>}</td>
            <td>{!rowData.delivery_date ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.delivery_date.slice(0, 10)}</div>}</td>
            <td>
                <button
                    disabled={!(!parseInt(rowData.delivery_date) && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowUpdate(true)}
                    className="btn btn-square btn-outline btn-sm btn-info mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                </button>
                <button
                    disabled={!(!parseInt(rowData.delivery_date) && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowDelete(true)}
                    className="btn btn-square btn-outline btn-sm btn-error ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                </button>
                {
                    showUpdate ?
                        <AddAndUpdateLcuCpu
                            handleForm={setShowUpdate}
                            refreshPage={dashboardRequest}
                            url='/update_lcu_cpu'
                            defaultBody={{
                                lcuCpuId: parseInt(rowData.lcu_cpu_id),
                                macAddress: rowData.mac_address_nexim,
                                windowsKey: rowData.windows_key
                            }} /> :
                        null
                }
                {
                    showDelete ?
                        <DeleteCard
                            show={showDelete}
                            handleForm={setShowDelete}
                            id={rowData.lcu_cpu_id}
                            dashboardRequest={dashboardRequest}
                            name="lcucpu" /> :
                        null
                }
            </td>
        </tr>
    );
}
