import {useEffect} from 'react';
import ComponentFileDb from './ComponentFileDb';
import { useTypeKey } from '../../hooks/useTypeKey';
import useFetch from '../../hooks/useFetch';

export default function PartViewer(props) {
    const { handleForm, id, url, title="Part Image", reduceSize=true } = props;
    const typeKeyHandle = () => {
        handleForm(false);
    }
    
    useTypeKey(typeKeyHandle, "Escape");

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}${url}`, {id:id})
    useEffect(() => {
        dataTable.handleRequest()
    }, [])

    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className={reduceSize?"bg-base-200 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto":"bg-base-200 p-6 rounded-lg shadow-lg relative w-full max-h-full overflow-y-auto"}>
                    <h3 className="font-bold text-lg mb-4">{title}</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                            {
                                dataTable.loading?
                                <progress className="progress w-full"></progress>:
                                <ComponentFileDb
                                printRef={null}
                                fileName={dataTable.data[0].file_name}
                                file={ dataTable.data[0].file_binary }
                                fistPage={true}
                                hoverColor={false} /> 
                            }
                    </form>
                </div>
            </div>
        </>
    );
}


