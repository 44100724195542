import { useState } from "react";
import DeleteCard from "../form/DeleteCard";

const NABadge = <div className="badge badge-neutral text-lg">N/A</div>


export default function ImmexLocationRow({ rowData, dashboardRequest, setLocationSelected, noActions = false, isAdmin }) {
    const [showDelete, setShowDelete] = useState(false);
    const viewLocation = (row) => {
        setLocationSelected(row);
        dashboardRequest();
    }
    return (
        <tr className="hover">
            <td><div className="badge badge-outline badge-primary">{rowData.immex_location_id}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal text-lg">{rowData.location}</div></td>
            <td>{parseInt(rowData.partidas) ? <div className="badge badge-outline badge-base-100 text-lg">{rowData.partidas}</div> : NABadge}</td>
            <td>{
                parseInt(rowData.audits) ? 
                <div className={parseInt(rowData.audits)=== parseInt(rowData.partidas)? 
                    "badge badge-success text-lg":
                    "badge badge-error text-lg"}>
                        {rowData.audits}</div> 
                : NABadge}
            </td>
            <td>{parseInt(rowData.partnumbers) ? <div className="badge badge-outline badge-base-100 text-lg">{rowData.partnumbers}</div> : NABadge}</td>
            <td>{parseInt(rowData.pedimentos) ? <div className="badge badge-outline badge-base-100 text-lg">{rowData.pedimentos}</div> : NABadge}</td>
            <td>{parseInt(rowData.stock_qty) ? <div className="badge badge-outline badge-base-100 text-lg">{rowData.stock_qty}</div> : NABadge}</td>
            <td>{parseInt(rowData.jpids) ? <div className="badge badge-outline badge-base-100 text-lg">{rowData.jpids}</div> : NABadge}</td>
            {
                !noActions ?
            <td>
                <div className="flex">
                    <button
                        disabled={!parseInt(rowData.partidas)}
                        onClick={() => viewLocation(rowData)}
                        className="btn btn-square btn-sm btn-info mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
                            <path strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </button>
                    <button
                        disabled={!((isAdmin.userImmexRole === 'admin') && !parseInt(rowData.partidas))}
                        onClick={() => setShowDelete(true)}
                        className="btn btn-square btn-sm btn-error ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                        </svg>
                    </button>
                </div>

            </td>:null}
            <DeleteCard
                show={showDelete}
                handleForm={setShowDelete}
                id={rowData.immex_location_id}
                dashboardRequest={dashboardRequest}
                name="ImmexLocation" />
        </tr>
    );
}