export default function TextArea(props) {
    const { title, value, keyName, selectorHandle } = props;
    return (
        <div className="form-control">
            <label for="large-input" class="block mb-2 text-sm font-medium font-bold">{title}</label>
            <textarea
                id="message"
                rows="4"
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
                name={title.toLowerCase()}
                required 
                className="rounded-lg border border-gray-400 p-2 leading-tight"
            />
        </div>
    )
}