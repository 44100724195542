import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../../components/tables/TableOrGrid";
import LcuBoxRow from "../../components/tables/LcuBoxRow";
import BarLcuBox from "../../components/ui/BarLcuBox";
import { getUserData } from "../../utils/utils";

export default function LcuBox() {
    const [searchLcu, setSearchLcu] = useState('');
    const userInfo= getUserData();
    const getBoxes = useFetch(`${process.env.REACT_APP_API_URL}get_lcu_box`,{
        lcuBox:searchLcu
    })
    useEffect(() => {
        getBoxes.handleRequest();
    }, [])
    console.log(userInfo)
    return (
        <div className="w-full max-w-screen-2xl m-auto">
            <BarLcuBox 
                refreshPage={getBoxes.handleRequest} 
                loading={getBoxes.loading}
                searchLcu={searchLcu} 
                setSearchLcu={setSearchLcu}
                userInfo={userInfo}/>
            <TableOrGrid
                loading={getBoxes.loading}
                isGrid={false}
                data={getBoxes.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={getBoxes.handleRequest}
                TableRow={LcuBoxRow}
                TableColumns={<tr>
                    <th>LCU ID</th>
                    <th>Serial Number</th>
                    <th>Create</th>
                    <th>Mac Address</th>
                    <th>Delivery No.</th>
                    <th>Action</th>
                </tr>} />
        </div>
    );
}