export default function TextInput(props) {
    const { title, value, keyName, selectorHandle } = props;
    return (
        <div className="form-control mb-4">
            <label for="large-input" class="block mb-2 text-sm font-medium">{title}</label>
            <input
                type="text"
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
                name={title.toLowerCase()}
                placeholder="Type here"
                className="input input-bordered"
                disabled={false}
            />
        </div>
    );
}