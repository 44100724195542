export default function BasicSelectorId(props) {
    const { title,value, keyName, selectorHandle, data, style="input input-bordered border-gray-400", disable=false, noneSelect=false } = props;
    return (
        <div className="form-control">
            <label for="large-input" class="block mb-2 text-sm font-medium">{title}</label>
            <select
                disable={disable}
                className={style}
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
            >
                {noneSelect ? <option selected value={null}>None</option>:null}
                {
                    data?.map((value, index) =>
                        <option key={index} value={value.id} label={value.name} />)
                }
            </select>
        </div>
    );
}