import { useState } from "react";
import AddAndUpdateLcuBox from "../form/AddAndUpdateLcuBox";
export default function BarLcuBox({ refreshPage, loading, searchLcu, setSearchLcu, userInfo }) {
    const [showAdd, setShowAdd] = useState(false);
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            refreshPage();
        }
    };
    return (
        <div className="navbar bg-base-100 my-4 rounded-xl ">
            <div className="flex-1">
                <a className="btn btn-ghost text-xl">LCU</a>
            </div>
            <div className="navbar-end">
                <label className="input input-bordered flex items-center gap-2">
                    <input
                        onKeyDown={handleKeyDown}
                        value={searchLcu}
                        onChange={(e) => setSearchLcu(e.target.value)}
                        type="text" className="grow" placeholder="Search LCU" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        className="h-4 w-4 opacity-70">
                        <path
                            fillRule="evenodd"
                            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                            clipRule="evenodd" />
                    </svg>
                </label>
                <button
                    onClick={() => refreshPage()}
                    className="btn btn-square btn-info ml-4">
                    {loading ?
                        <span className="loading loading-spinner"></span> :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                        </svg>
                    }

                </button>

                        <button
                            disabled={!(userInfo.userLcuManagerRole === 'admin')}
                            onClick={() => setShowAdd(true)}
                            className="btn btn-square btn-success ml-4">

                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                        </button> 
                {
                    showAdd ?
                        <AddAndUpdateLcuBox
                            handleForm={setShowAdd}
                            refreshPage={refreshPage}
                            url='/add_lcu_box' /> :
                        null
                }
            </div>
        </div>
    );
}