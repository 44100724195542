import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import SelectClients from "../../components/form/selectClients";
import PoTable from "../../components/tables/poTable";
import TanStackTable from "../../components/TanStackTable";
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content' 
import VacationList from "../../components/ui/VacationList";
import EditJigForm from "../../components/form/EditJigForm";

import { saveAs } from 'file-saver'



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
	faTicket,
	faTable,
	faChartSimple,
	faCartShopping,
	faMoneyCheck,
	faFileInvoiceDollar,
	faUserGroup,
	faPenToSquare,
	faTrashCan,
	faFileArrowDown
} from "@fortawesome/free-solid-svg-icons";
import AddQuoteForm from "../../components/form/AddQuoteForm";
import QuoteForm from "../../components/form/QuoteForm";

const moment = require("moment");

function QuotesTable(props) {
	const { changeTicket, userIdSelected, refresh, tickets } = props;
	
	var [refetch, setRefetch] = useState(false);
	const endpoint = process.env.REACT_APP_API_URL + "quotes";
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [poIdSelected, setPoIdSelected] = useState(0);
	const [selectedRow, setSelectedRow] = useState([]);

	const [hoursAfterSub, setHoursAfterSub] = useState(0.0);
	const [costAfterSub, setCostAfterSub] = useState(0.0);

	var searchedVal = ""

	var search = ""

	const [inputValue, setInputValue] = useState('')

	const MySwal = withReactContent(Swal)

	const downloadPDF = async (e) => {
		const id = JSON.parse(e.target.dataset.id);
		console.log(id);

		if (true) {
			const endpoint = process.env.REACT_APP_API_URL + "quotes/pdf/" + id;
			try {
				const response = await axios.get(
					endpoint,
					{
						headers: {
						  'Content-Type': 'multipart/form-data'
						},
						responseType: 'arraybuffer'
					  }
				);
	
				console.log(response);
				
				const blob = new Blob([response.data], { type: 'application/pdf' })
				console.log(blob);
				
  				saveAs(blob, "tickets.pdf")

				//toast.success(response.data.message);
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
		
	}

	const editSwal = async (e) => {
	  const row = JSON.parse(e.target.dataset.row);
		  console.log(row);
  
		  const { value: formValues } = await MySwal.fire({
			title: "Create Quote",
			html: `<div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Quantity</span>
				</label>
				<textarea id="tyc" name="tyc" rows="10" cols="50" className="border w-full">`+row.tyc.trim()+`</textarea>
			</div>`,
			focusConfirm: false,
			preConfirm: () => {
			  return {
				"id": row.id,
				"tyc": document.getElementById("tyc").value,
				"userId": localStorage.getItem("userId")
				//document.getElementById("swal-input2").value
			  };
			}
		  });
		  if (formValues) {
			console.log(formValues);
			handleEditTyc(formValues);
		  }
	}

	const addSwal = async (e) => {
        
			const { value: formValues } = await MySwal.fire({
				//title: "Create Quote",
				html: <QuoteForm/>,
				focusConfirm: false,
				showConfirmButton: false,
      			showCancelButton: false,
				width: "90%",
				
				preConfirm: () => {
				  return {
					"tyc": document.getElementById("tyc").value,
					"userId": localStorage.getItem("userId")
					//document.getElementById("swal-input2").value
				  };
				}
			  });
			  if (formValues) {
				console.log(formValues);
				handleAddJig(formValues);
			  }
	  }

	  const handleAddJig = async tyc => {
		//event.preventDefault();
	
		if (true) {
			const endpoint = process.env.REACT_APP_API_URL + "tyc";
			try {
				const response = await axios.post(
					endpoint,
					tyc,
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
	
				console.log(response.data);
				console.log(true);
				toast.success(response.data.message);
				setRefetch(!refetch);
			} catch (error) {
				console.log(error);
				toast.error(error);
			}
		} else {
			console.log("error");
		}
	
		//console.log(clientId, localStorage.getItem("userId"));
	
		//const userEmail = event.target.userEmail.value;
	};
  
	const deleteSwal = async (e) => {
	  const id = e.target.dataset.id;
		  console.log(id);
	  if (id) {
		  Swal.fire({
			  title: "Are you sure?",
			  text: "You won't be able to revert this!",
			  icon: "warning",
			  showCancelButton: true,
			  confirmButtonColor: "#3085d6",
			  cancelButtonColor: "#d33",
			  confirmButtonText: "Yes, delete it!"
			}).then((result) => {
			  if (result.isConfirmed) {
				  handleDeleteTyc(id);
				/*Swal.fire({
				  title: "Deleted!",
				  text: "Your file has been deleted.",
				  icon: "success"
				});*/
			  }
			});
	  }
	}
  
	const handleDeleteTyc = async id => {
	  //event.preventDefault();
  
	  if (true) {
		  const endpoint = process.env.REACT_APP_API_URL + "tyc/" + id;
		  try {
			  const response = await axios.delete(
				  endpoint,
				  {
					  headers: {
						  "Content-Type": "application/json",
					  },
				  }
			  );
  
			  console.log(response.data);
			  console.log(true);
			  toast.success(response.data.message);
			  setRefetch(!refetch);
		  } catch (error) {
			  console.log(error);
			  toast.error(error);
		  }
	  } else {
		  console.log("error");
	  }
  
	  //console.log(clientId, localStorage.getItem("userId"));
  
	  //const userEmail = event.target.userEmail.value;
  };
  
  const handleEditTyc = async row => {
	  //event.preventDefault();
  
	  if (true) {
		  const endpoint = process.env.REACT_APP_API_URL + "tyc";
		  try {
			  const response = await axios.put(
				  endpoint,
				  row,
				  {
					  headers: {
						  "Content-Type": "application/json",
					  },
				  }
			  );
  
			  console.log(response.data);
			  console.log(true);
			  toast.success(response.data.message);
			  setRefetch(!refetch);
		  } catch (error) {
			  console.log(error);
			  toast.error(error);
		  }
	  } else {
		  console.log("error");
	  }
  
	  //console.log(clientId, localStorage.getItem("userId"));
  
	  //const userEmail = event.target.userEmail.value;
  };
	
	var curr = new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);

	const [clientSelected, setClientSelected] = useState();
	const changeTab = index => {
		console.log(index);
		setClientSelected(index.id);
	};

	const [test, setTest] = useState(null);

	const [inputText, setInputText] = useState("");
  let inputHandler = (e) => {
    //convert input text to lower case
    var searchedVal = e.target.value.toLowerCase();
	search = searchedVal
	var newData = data.filter((row) =>
		// note that I've incorporated the searchedVal length check here
		!searchedVal.length || row.po
		  .toString()
		  .toLowerCase()
		  .includes(searchedVal.toString().toLowerCase()) 
	  )

	  setData(newData)
    //setInputText(lowerCase);
  };

	useEffect(() => {
		console.log("ready ", endpoint);
		setLoading(true);
		axios
			.get(endpoint, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then(response => {
				setData(response.data.data);
				console.log(response.data);
			})
			.catch(error => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [refresh, refetch]);

	const columns = [
		{
			name: '#',
			selector: row => row.id,
			sortable: true,
			grow: 0,
			style: {
				//backgroundColor: 'rgba(63, 195, 128, 0.9)',
				//color: 'white',
				width: '75px',
				maxWidth: '75px',
				minWidth: '75px',
			},
		},
		{
			name: 'Client',
			selector: row => row.client.name,
			sortable: true,
		},
		{
			name: 'Requisitor',
			selector: row => row.requisitor,
			sortable: true,
		},
		{
			name: 'Requisitor Email',
			selector: row => row.requisitorMail,
			sortable: true,
		},
		{
			name: 'FSE',
			selector: row => row.fse,
			sortable: true,
		},
		{
			name: 'total',
			selector: row => row.total,
			sortable: true,
		},
		{
			name: 'TyC',
			selector: row => row.tyc.name,
			// selector: row => row.tyc.substring(0,100) + "...",
			sortable: true,
		},
		{
			name: 'Creator',
			selector: row => row.user.name,
			sortable: true,
		},
		{
			name: 'Date',
			selector: row => row.date,
			sortable: true,
		},
		/*{
			name: 'Last modified',
			selector: row => row.updatedAt,
			sortable: true,
		},*/
		
		/*{
			name: 'rowCreator',
			selector: row => row.rowCreator,
			sortable: true,
		},
		{
			name: 'plant',
			selector: row => row.plant,
			sortable: true,
		},*/
		{
			name: '',
			button: true,
			width: '48px',
			cell: row => (
				<div className="btn btn-sm btn-accent text-white " data-id={row.id} data-row={JSON.stringify(row)} onClick={downloadPDF}>
					<FontAwesomeIcon icon={faFileArrowDown} className="pointer-events-none"/>
				</div>
			),

		},
		{
			name: '',
			button: true,
			width: '48px',
			cell: row => (
				<div className="btn btn-sm btn-error text-white " data-id={row.id} data-row={JSON.stringify(row)} onClick={deleteSwal}>
					<FontAwesomeIcon icon={faTrashCan} className="pointer-events-none"/>
				</div>
			),

		},
		/*{
			name: '',
			button: true,
			cell: row => (
				<div className="flex justify-center items-center">
					<div className="btn btn-xs btn-outline mr-2" data-id={row.id} data-row={JSON.stringify(row)} onClick={downloadPDF}>Open</div>
					<div className="btn btn-xs btn-info mr-2 hidden" data-id={row.id} data-row={JSON.stringify(row)} onClick={editSwal}>Edit</div>
					<div className="btn btn-xs btn-error" data-id={row.id} data-row={JSON.stringify(row)} onClick={deleteSwal}>Del</div>
				</div>
			),
		},*/
		
		
	];

	/*<FontAwesomeIcon icon={faPenToSquare} className="mr-2 text-xl text-sky-600 cursor-pointer" data-id={row.id} onClick={handleDelete}/>
					<FontAwesomeIcon icon={faTrashCan} className="mr-2 text-xl text-red-600 cursor-pointer" data-id={row.id} onClick={handleDelete}/> */

	const ExpandedComponent = ({ data }) => 
	<div className="flex flex-wrap	overflow-hidden">
		<div className="w-full overflow-hidden hidden">
			<button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" data-id={data.id} onClick={handleDelete}>Delete</button>
		</div>
		<div className="w-full">
	 		<pre>{JSON.stringify(data, null, 2)}</pre>
		 </div>
	</div>;

	const handleDelete = (e) => {		
		const id = e.target.dataset.id;
		console.log(id);
		
	  };

	const handleSearch = (e) => {
		searchedVal = e.target.value.toLowerCase();
		var filteredItems = data.filter(
			item => item.po && item.po.toLowerCase().includes(searchedVal.toLowerCase()),
		);

		console.log("filteresItems ", filteredItems);
		console.log("searchedVal ", searchedVal);
		
	
		setData(filteredItems);
	  };

	  function convertArrayOfObjectsToCSV(array) {
		let result;
	
		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(data[0]);
	
		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;
	
		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
	
				result += item[key];
				
				ctr++;
			});
			result += lineDelimiter;
		});
	
		return result;
	}
	
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
	
		const filename = 'export.csv';
	
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
	
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	
	const Export = ({ onExport }) => 
		<>
			<button className="btn join-item btn-neutral mt-2 hidden" onClick={addSwal}>Create Quote</button>
			<button className="btn join-item btn-neutral mt-2" onClick={e => onExport(e.target.value)}>Export</button>
		</>;

		const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

		const FilterComponent = ({ filterText, onFilter, onClear }) => (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Name"
					aria-label="Search Input"
					value={filterText}
					onChange={onFilter}
				/>
				<button type="button" onClick={onClear}>
					X
				</button>
			</>
		);
	
		const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	var filteredItems = null;
	if (data) {
		filteredItems = data.filter( 
			item => item.po && item.po.toLowerCase().includes(filterText.toLowerCase()),
		);
	}

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	const conditionalRowStyles = [
		/*{
			when: row => row.costLeft > 3000 ,
			style: {
				backgroundColor: 'rgba(63, 195, 128, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.calories >= 300 && row.calories < 400,
			style: {
				backgroundColor: 'rgba(248, 148, 6, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		{
			when: row => row.costLeft == 0,
			style: {
				backgroundColor: 'rgba(242, 38, 19, 0.9)',
				color: 'white',
				'&:hover': {
					cursor: 'not-allowed',
				},
			},
		},*/
	];

	const handleChange = ({ selectedRows }) => {
		// You can set state or dispatch with something like Redux so we can use the retrieved data
		setSelectedRow(selectedRows);

		


		
		console.log('Selected Rows: ', selectedRows);
		console.log("selected row: ", selectedRow);
		
	  };


	if (loading) 
		return (
			<div className="w-full h-full flex justify-center align-middle">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);

	return (
		<div>

			
 
<div className="">
{false ?
				<DataTable
				title="Quotes Table"
				columns={columns}
				data={filteredItems}
				selectableRows
				onSelectedRowsChange={handleChange}
				expandableRows
				expandableRowsComponent={ExpandedComponent}
				pagination
				actions={actionsMemo}
				paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
				//subHeaderComponent={subHeaderComponentMemo}
			/>
			: data ? <DataTable
			title="Quotes Table"
			columns={columns}
			data={data}
			selectableRows
			onSelectedRowsChange={handleChange}
			expandableRows
			expandableRowsComponent={ExpandedComponent}
			pagination
			actions={actionsMemo}
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			//subHeaderComponent={subHeaderComponentMemo}
			conditionalRowStyles={conditionalRowStyles}
		/>
		:<></>
			}
			</div>

</div>
	);
}

export default QuotesTable;
