export default function BasicSelector(props) {
    const { title, allowAll,value, keyName, selectorHandle, data } = props;
    return (
        <div className="form-control">
            <label for="large-input" class="block mb-2 text-sm font-medium">{title}</label>
            <select
                className="input input-bordered border-gray-400"
                name={title.toLowerCase()}
                value={value}
                onChange={(e)=>selectorHandle(keyName,e.target.value)}
            >
                {allowAll ? <option selected>ALL</option>:<option selected value={''}>None</option>}
                {
                    data?.map((value, index) =>
                        <option key={index} value={value.name} label={value.name} />)
                }
            </select>
        </div>
    );
}