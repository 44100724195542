import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../tables/TableOrGrid";
import ImmexCheckoutItemPedimentoRow from "../tables/ImmexCheckoutItemPedimentoRow";
const limitItemsByQty = (listIn, limitQty) =>{
    let qtyCollector = 0;
    let indexCollector = 1;
    for(let item of listIn){
        qtyCollector += parseInt(item.total_qty);
        if(qtyCollector>=limitQty)break;
        indexCollector++;
    } 
    return listIn.slice(0,indexCollector);
}

export default function ImmexCheckoutItemChecker(props) {
    const { handleForm, itemData, dashboardRequest } = props;

    const getCheckoutItemPedimento = useFetch(`${process.env.REACT_APP_API_URL}get_checkout_item_pedimento`,
        { 
            immex_partnumber_id: itemData.immex_partnumber_id, 
            immex_checkout_items_id:itemData.immex_checkout_items_id 
        })

    const refreshAll = () =>{
        dashboardRequest();
        getCheckoutItemPedimento.handleRequest();
    }

    useEffect(() => {
        getCheckoutItemPedimento.handleRequest();
    }, [])

    const dataTableParts = limitItemsByQty(getCheckoutItemPedimento.data, parseInt(itemData.qty));
    const pickupQty = dataTableParts.reduce((accumulator, currentValue)=>accumulator + parseInt(currentValue.discount_qty), 0);
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Item Checker</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                        <div className="rounded-md bg-gray-800 p-2">
                            <h1 className="text-xl font-bold text-white">Partnumber: <br />
                                <p className="text-yellow-400">{itemData.partnumber}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Description: <br />
                                <p className="text-yellow-400">{itemData.description}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Cart Quantity: <br />
                                <p className="text-yellow-400">{itemData.qty}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Pickup Quantity: <br />
                                <p className="text-yellow-400">{pickupQty }</p>
                            </h1>
                            
                        </div>

                        <TableOrGrid
                            pagerButton={false}
                            pagerTop={false}
                            loading={false}
                            isGrid={false}
                            data={dataTableParts}
                            GridCard={null}
                            isAdmin={null}
                            dashboardRequest={refreshAll}
                            TableRow={ImmexCheckoutItemPedimentoRow}
                            TableColumns={<tr>
                                <th>JP ID</th>
                                <th>Location</th>
                                <th>Stock Qty</th>
                                <th>Pickup Qty</th>
                                <th>Actions</th>
                            </tr>} 
                            partnumber={itemData.partnumber}
                            checkout_items_id={itemData.immex_checkout_items_id}
                            cartQty={itemData.qty}
                            pickupQty={pickupQty}/>
                    </form>
                </div>
            </div>
        </>
    );
}


