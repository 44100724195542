import { moneyKHandle, sumValuesByProperty, 
    sumRowsByProperty, countRowsByProperty, separator } from "../../utils/utils";
import CountDown from "./CountDown";


export default function HeadStatsIndividual(props) {
    const { data, dayThreshold, name } = props;
    
    return (
        <div className="lg:flex lg:justify-center mt-2 w-full">
            <div className="grid lg:grid-flow-col lg:auto-cols-auto gap-4 grid-flow-row auto-rows-max">
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-amber-500">

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Awaiting Quantity</div>
                    <div className="stat-value text-amber-400 text-3xl pt-3">#{countRowsByProperty(data,"status", "AWAITING")}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Rejected Quantity</div>
                    <div className="stat-value text-red-400 text-3xl pt-3">#{countRowsByProperty(data,"status", "REJECTED")}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-title">Time Left </div>
                    <CountDown dayThreshold={dayThreshold}/>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-amber-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Total {name}</div>
                    <div className="stat-value text-amber-400 text-2xl pt-2">${separator(moneyKHandle(sumValuesByProperty(data, "amount")))}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Authorized {name}</div>
                    <div className="stat-value text-green-400 text-2xl pt-2">${separator(moneyKHandle(sumRowsByProperty(data, "status", "APPROVED")))}</div>
                </div>
            </div>

        </div>
    );
}