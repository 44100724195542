import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
	faTicket,
	faTable,
	faChartSimple,
	faCartShopping,
	faMoneyCheck,
	faFileInvoiceDollar,
	faUserGroup,
	faQuoteLeft
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import ArrowDown from "../components/ui/arrowDown";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

import useFetchSelector from "../hooks/useFetchSelector";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Menu = props => {
	const { userType, permissions } = props.roles;
	localStorage.setItem("tickets", permissions.tickets);
	const [utilsDropdownOpen, setUtilsDropdownOpen] = useState(false);
	const [jigsDropdownOpen, setJigsDropdownOpen] = useState(false);
	const [scheduleDropdownOpen, setScheduleDropdownOpen] = useState(false);
	const [expensesDropdownOpen, setExpensesDropdownOpen] = useState(false);
	const [warehouseDropdownOpen, setWarehouseDropdownOpen] = useState(false);
	const [immexDropdownOpen, setImmexDropdownOpen] = useState(false);
	const [ticketsDropdownOpen, setTicketsDropdownOpen] = useState(false);
	const [quotesDropdownOpen, setQuotesDropdownOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(true);
	const [theme, setTheme] = useState(localStorage.getItem("theme"));
	const [appSelected, setAppSelected] = useState("");
	const { setAuth } = useAuth();
	const navigate = useNavigate();

	//EXPENSES
	useFetchSelector(`${process.env.REACT_APP_API_URL}users_selector`, 'users');
	useFetchSelector(`${process.env.REACT_APP_API_URL}principal_selector`, 'principals');
	useFetchSelector(`${process.env.REACT_APP_API_URL}status_selector`, 'status');
	useFetchSelector(`${process.env.REACT_APP_API_URL}clients_selector`, 'clients');
	useFetchSelector(`${process.env.REACT_APP_API_URL}expenses_types_selector`, 'expense_types');
	useFetchSelector(`${process.env.REACT_APP_API_URL}butget_types_selector`, 'budget_types');

	//USER VIEWER
	useFetchSelector(`${process.env.REACT_APP_API_URL}user_department_selector`, 'user_departments');
	useFetchSelector(`${process.env.REACT_APP_API_URL}user_location_selector`, 'user_locations');
	useFetchSelector(`${process.env.REACT_APP_API_URL}user_organization_selector`, 'user_organizations');
	useFetchSelector(`${process.env.REACT_APP_API_URL}user_position_selector`, 'user_positions');

	const toggleTheme = () => {
		localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
		setTheme(theme === "dark" ? "light" : "dark");
	};

	const handleLogout = () => {
		setAuth(null);
		navigate("/", { replace: true });
	};

	useEffect(() => {
		document.querySelector("html").setAttribute("data-theme", theme);
	}, [theme]);

	const close = e => {
		setMenuOpen(!menuOpen);
		document.getElementById("my-drawer-3").click();
	};

	return (
		<div
			className={`drawer h-screen drawer-mobile transition-all delay-150 duration-300 ease-in-out  ${menuOpen ? "" : ""
				}`}
		>
			<input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content  flex flex-col transition-all delay-1000 duration-1000 ease-in-out">
				<div className="navbar flex justify-between shadow-md">
					<div>
						<div className="flex-none ">
							<label htmlFor="my-drawer-3" className="btn btn-square btn-ghost">
								<svg
									className="swap-off fill-current"
									xmlns="http://www.w3.org/2000/svg"
									width="32"
									height="32"
									viewBox="0 0 512 512"
								>
									<path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
								</svg>
							</label>
						</div>

						{/*<label className="btn btn-circle swap swap-rotate  lg:hidden">
							<input
								type="checkbox"
								onClick={() => close()}
							/>

							<svg
								className="swap-off fill-current"
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 512 512"
							>
								<path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
							</svg>

							<svg
								className="swap-on fill-current"
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 512 512"
							>
								<polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
							</svg>
						</label>*/}

						<h1 className="text-2xl font-semibold ml-2">{appSelected}</h1>
					</div>

					<div className="order-last">
						<label className="swap swap-rotate">
							<input onClick={toggleTheme} type="checkbox" />
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								aria-hidden="true"
								data-set-theme="light"
								className="fill-current w-6 h-6 swap-on"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
								></path>
							</svg>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								aria-hidden="true"
								data-set-theme="dark"
								className="fill-current w-6 h-6 swap-off ACTIVECLASS"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
								></path>
							</svg>
						</label>

						<div className="dropdown dropdown-end ml-4">
							<label tabIndex={0} className="btn btn-ghost btn-circle avatar">
								<div className="w-10 rounded-full leading-10">
									<FontAwesomeIcon icon={faUser} size="xl" />
								</div>
							</label>
							<ul
								tabIndex={0}
								className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 z-20"
							>
								<li className="justify-between">
									<Link to="/profile" onClick={() => setAppSelected("Profile Settings")}>
										Profile Settings
										<span className="badge hidden">disabled</span>
									</Link>
								</li>
								<div className="divider mt-0 mb-0"></div>
								<li onClick={handleLogout}>
									<a>Logout</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<main className="max-h-[calc(100vh-64px)] flex-1 overflow-y-auto bg-base-200 border-box p-4">
					<Outlet />
				</main>
			</div>
			<div className="drawer-side transition-all delay-150 duration-300 ease-in-out z-50">
				<label htmlFor="my-drawer-3" className="drawer-overlay"></label>
				<ul className="menu p-4 w-80 bg-base-100 h-screen z-50">
					<li className="mb-2 mt-2 font-semibold text-xl">
						<a className="flex justify-center">
							<img
								className="w-48 text-base-100"
								src={theme == "dark" ? "/images/pacLogoWhite.svg" : "/images/pacLogoBlack.svg"}
							/>
						</a>
					</li>
					<li></li>
					<li>
						<Link to="/dashboard" className="w-full" onClick={() => {
							setAppSelected("Home")
							document.getElementById('my-drawer-3').checked = false
						}}>
							<FontAwesomeIcon icon={faHouse} />
							Home
						</Link>
					</li>

					{permissions.reports == "admin" ? ( //despues agregar permisos especiales
						<li>
							<Link
								to="/charts/reports"
								className="w-full"
								onClick={() => {
									setAppSelected("Reports Charts")
									document.getElementById('my-drawer-3').checked = false
								}}
							>
								<FontAwesomeIcon icon={faChartSimple} />
								Reports Charts beta v1.1
							</Link>
						</li>
					) : (
						<></>
					)}
					{
						"userViewer" in permissions &&
						(<li>
							<Link
								to="/user/viewer"
								className="w-full"
								onClick={() => {
									setAppSelected("Users Viewer")
									document.getElementById('my-drawer-3').checked = false
								}}
							>
								<FontAwesomeIcon icon={faUserGroup} />
								User Viewer v1.1
							</Link>
						</li>)
					}
					{"immex" in permissions ? ( //despues agregar permisos especiales
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setImmexDropdownOpen(immexDropdownOpen => !immexDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faMoneyCheck} className="mr-2" />
									IMMEX
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!immexDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!immexDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/immex/partnumber"
											className="w-full"
											onClick={() => {
												setAppSelected("Partnumbers")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											Partnumbers
										</Link>
									</li>
									<li>
										<Link
											to="/immex/pedimento"
											className="w-full"
											onClick={() => {
												setAppSelected("Pedimento")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faFileInvoiceDollar} />
											Pedimento
										</Link>
									</li>
									<li>
										<Link
											to="/immex/partida"
											className="w-full"
											onClick={() => {
												setAppSelected("Partida")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faFileInvoiceDollar} />
											Partida
										</Link>
									</li>
									<li>
										<Link
											to="/immex/checkout"
											className="w-full"
											onClick={() => {
												setAppSelected("Checkout")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faFileInvoiceDollar} />
											Checkout
										</Link>
									</li>
									<li>
										<Link
											to="/immex/location"
											className="w-full"
											onClick={() => {
												setAppSelected("Location")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faFileInvoiceDollar} />
											Location
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : (
						<></>
					)}
					{"LCU Manager" in permissions ? ( //despues agregar permisos especiales
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setWarehouseDropdownOpen(warehouseDropdownOpen => !warehouseDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faMoneyCheck} className="mr-2" />
									LCU Manager
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!warehouseDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!warehouseDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/warehouse/lcubox"
											className="w-full"
											onClick={() => {
												setAppSelected("LCU")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											LCU
										</Link>
									</li>
									<li className="w-full">
										<Link
											to="/warehouse/cpu"
											className="w-full"
											onClick={() => {
												setAppSelected("CPU")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											CPU
										</Link>
									</li>
									<li className="w-full">
										<Link
											to="/warehouse/delivery"
											className="w-full"
											onClick={() => {
												setAppSelected("Delivery")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											Delivery
										</Link>
									</li>

									<li className="w-full">
										<Link
											to="/warehouse/bom"
											className="w-full"
											onClick={() => {
												setAppSelected("Bom")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											Bom
										</Link>
									</li>
									<li className="w-full">
										<Link
											to="/warehouse/partnumber"
											className="w-full"
											onClick={() => {
												setAppSelected("Partnumbers")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											Partnumbers
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : (
						<></>
					)}
					{"expenses" in permissions ? ( //despues agregar permisos especiales
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setExpensesDropdownOpen(expensesDropdownOpen => !expensesDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faMoneyCheck} className="mr-2" />
									Expenses v1.1
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!expensesDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!expensesDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/user/home"
											className="w-full"
											onClick={() => {
												setAppSelected("Home")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUserGroup} />
											Home
										</Link>
									</li>
									<li className="w-full">
										<Link
											to="/user/budget"
											className="w-full"
											onClick={() => {
												setAppSelected("Budget")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faMoneyCheck} />
											Budget
										</Link>
									</li>
									<li>
										<Link
											to="/user/expense"
											className="w-full"
											onClick={() => {
												setAppSelected("Expense")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faFileInvoiceDollar} />
											<span>Expense</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : (
						<></>
					)}

					{permissions.schedule == "admin" || permissions.schedule == "superUser" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setScheduleDropdownOpen(scheduleDropdownOpen => !scheduleDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
									Schedule v1.1
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!scheduleDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!scheduleDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/schedule/admin"
											className="w-full"
											onClick={() => {
												setAppSelected("Schedule Admin")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUserShield} />
											Admin View
										</Link>
									</li>
									<li>
										<Link
											to="/schedule/user"
											className="w-full"
											onClick={() => {
												setAppSelected("Schedule User")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUser} />
											<span>User View</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : permissions.schedule == "user" ? (
						<li>
							<Link
								to="/schedule/user"
								className="w-full"
								onClick={() => {
									setAppSelected("Schedule User")
									document.getElementById('my-drawer-3').checked = false
								}}
							>
								<FontAwesomeIcon icon={faCalendarDays} />
								Schedule v1.1
							</Link>
						</li>
					) : (
						<></>
					)}

					{permissions.tickets == "admin" || permissions.tickets == "fse" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setTicketsDropdownOpen(ticketsDropdownOpen => !ticketsDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
									SHDesk beta v1.2
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!ticketsDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!ticketsDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className={`${permissions.tickets == "fse" ? "hidden" : ""} w-full `}>
										<Link
											to="/tickets/dashboard"
											className="w-full"
											onClick={() => {
												setAppSelected("Tickets Dashboard")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faChartSimple} />
											Dashboard
										</Link>
									</li>
									<li className="w-full">
										<Link
											to="/tickets/admin"
											className="w-full"
											onClick={() => {
												setAppSelected("Tickets Admin")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUserShield} />
											Tickets table
										</Link>
									</li>
									<li className={`${permissions.tickets == "fse" ? "hidden" : ""} w-full `}>
										<Link
											to="/tickets/usersTable"
											className="w-full"
											onClick={() => {
												setAppSelected("Tickets Users Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											Users Table
										</Link>
									</li>
									<li className="hidden">
										<Link
											to="/schedule/user"
											className="w-full"
											onClick={() => {
												setAppSelected("Schedule User")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUser} />
											<span>User View</span>
										</Link>
									</li>
									<li>
										<Link
											to="/tickets/new"
											className="w-full"
											onClick={() => {
												setAppSelected("New Ticket")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTicket} />
											<span>New Ticket</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : permissions.tickets == "user" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setTicketsDropdownOpen(ticketsDropdownOpen => !ticketsDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
									SHDesk beta v1.2
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!ticketsDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!ticketsDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/tickets/user"
											className="w-full"
											onClick={() => {
												setAppSelected("Tickets Admin")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											Tickets
										</Link>
									</li>
									<li>
										<Link
											to="/tickets/new"
											className="w-full"
											onClick={() => {
												setAppSelected("New Ticket")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTicket} />
											<span>New Ticket</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : (
						<></>
					)}

					{permissions.jigs == "admin" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setJigsDropdownOpen(jigsDropdownOpen => !jigsDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
									Jigs beta
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!jigsDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!jigsDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/jigs"
											className="w-full"
											onClick={() => {
												setAppSelected("Jigs Admin")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faUserShield} />
											Jigs Admin
										</Link>
									</li>
									<li>
										<Link
											to="/jigsTable"
											className="w-full"
											onClick={() => {
												setAppSelected("Jigs Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											<span>Jigs Table</span>
										</Link>
									</li>
								</ul>
							</div>
						</li>
					) : (
						<></>
					)}

					{permissions.purchaseOrders == "admin" ? (
						<li>
							<Link
								to="/po"
								className="w-full"
								onClick={() => {
									setAppSelected("Purchase Orders")
									document.getElementById('my-drawer-3').checked = false
								}}
							>
								<FontAwesomeIcon icon={faCartShopping} />
								Purchase Orders
							</Link>
						</li>
					) : (
						<></>
					)}

					{permissions.quotes == "admin" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setQuotesDropdownOpen(quotesDropdownOpen => !quotesDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faQuoteLeft} className="mr-2" />
									Quotes
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!quotesDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!quotesDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/quotes/create"
											className="w-full"
											onClick={() => {
												setAppSelected("Create Quote")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											Create Quote
										</Link>
									</li>

									<li className="w-full">
										<Link
											to="/quotes"
											className="w-full"
											onClick={() => {
												setAppSelected("Quotes Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											Quotes Table
										</Link>
									</li>

									<li className="w-full">
										<Link
											to="/utils/tyc"
											className="w-full"
											onClick={() => {
												setAppSelected("TyC Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											TyC Table
										</Link>
									</li>
								</ul>
							</div>
						</li>

						
					) : (
						<></>
					)}

					{permissions.utils == "admin" ? (
						<li>
							<div
								className="w-full flex justify-between"
								onClick={() =>
									setUtilsDropdownOpen(utilsDropdownOpen => !utilsDropdownOpen)
								}
							>
								<Link className="w-full">
									<FontAwesomeIcon icon={faCalendarDays} className="mr-2" />
									Utils
								</Link>
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`delay-400 duration-500 transition-all ${!utilsDropdownOpen && "rotate-180"
										}
											`}
								/>
							</div>
							<div
								className={`${!utilsDropdownOpen ? "-top-20 hidden delay-0" : "top-0 delay-900"
									} relative duration-900 ease-in transition-all delay-500 overflow-hidden `}
							>
								<ul className="w-full menu menu-compact">
									<li className="w-full">
										<Link
											to="/utils/clients"
											className="w-full"
											onClick={() => {
												setAppSelected("Clients Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											Clients Table
										</Link>
									</li>

									<li className="w-full">
										<Link
											to="/utils/tyc"
											className="w-full"
											onClick={() => {
												setAppSelected("TyC Table")
												document.getElementById('my-drawer-3').checked = false
											}}
										>
											<FontAwesomeIcon icon={faTable} />
											TyC Table
										</Link>
									</li>
								</ul>
							</div>
						</li>


					) : (
						<></>
					)}
				</ul>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme={theme}
			/>
		</div>
	);
};

export default Menu;
