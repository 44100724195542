
import { useState } from "react";
import { CellNull } from "./CellNull";
import { handleImage } from "../../utils/utils";
import UserOrgForm from "../form/UserOrgForm";

const listColors = {
    "blue": "rounded-lg card w-12 ring-4 ring-blue-400 hover:ring-8",
    "green": "rounded-lg card w-12 ring-4 ring-green-600 hover:ring-8",
    "violet": "rounded-lg card w-12 ring-4 ring-violet-600 hover:ring-8",
    "orange": "rounded-lg card w-12 ring-4 ring-orange-600 hover:ring-8",
    "fuchsia": "rounded-lg card w-12 ring-4 ring-fuchsia-600 hover:ring-8",
    "pink": "rounded-lg card w-12 ring-4 ring-pink-600 hover:ring-8",
    "slate": "rounded-lg card w-12 ring-4 ring-slate-600 hover:ring-8",
    "red": "rounded-lg card w-12 ring-4 ring-red-600 hover:ring-8"
}

export default function UsersViewerUserRow({ rowData, isAdmin, dashboardRequest, sessionUserId }) {
    const [showEdit, setShowEdit] = useState(false);
    const profile_image = rowData.profile_image ? handleImage(rowData.profile_image.data) : "/images/Profile_Default.png"
    return (
        <tr className="hover">
            <td><div className="badge badge-outline badge-primary">{rowData.id}</div></td>
            <td>
                <img
                    onClick={() => setShowEdit(true)}
                    className={listColors[rowData["color"]]}
                    src={profile_image}
                    alt="profile"
                    width="100%" />
            </td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.user_name}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.email}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.phone_number}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.user_city}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.department}</div></td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.user_position}</div></td>
            <td>
                {
                    rowData.day_by_day ?
                        <p className="text-center bg-success text-xs text-bold text-base-100 p-1 rounded-lg" >
                            Day by Day<br /> ✈️🏖️🏕️🏔️</p> : CellNull
                }
            </td>
            <td>
                {
                    rowData.vacation ?
                        <p className="text-center bg-success text-xs text-bold text-base-100 p-1 rounded-lg" >
                            Vacation<br /> ✈️🏖️🏕️🏔️</p> : CellNull
                }
            </td>
            <td>{!rowData.birthday_date ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.birthday_date.slice(0, 10)}</div>}</td>
            <td>{!rowData.hired_date ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.hired_date.slice(0, 10)}</div>}</td>

            <UserOrgForm
                sessionUserId={sessionUserId}
                isAdmin={isAdmin}
                show={showEdit}
                handleForm={setShowEdit}
                title="Account"
                userInfo={rowData}
                dashboardRequest={dashboardRequest} />
        </tr>
    );
}
