
import { CellNull } from "./CellNull";
import { useState } from "react";
import DeleteCard from "../form/DeleteCard";
import AddAndUpdateLcuDelivery from "../form/AddAndUpdateLcuDelivery";
import SetLcuDeliveryDate from "../form/SetLcuDeliveryDate";
import { format } from "date-fns";
import { dateFormatDB } from "../../utils/constants";

export default function LcuDeliveryRow({ rowData, isAdmin, dashboardRequest }) {
    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showPass, setShowPass] = useState(false);
    return (
        <tr className="hover">
            <td><div className="badge badge-outline badge-primary">{rowData.lcu_delivery_id}</div></td>
            <td>{!rowData.serialnumber ? CellNull : <div className="bg-base-100 rounded-lg p-2 font-bold text-normal">{rowData.serialnumber}</div>}</td>
            <td>{!rowData.client_name ? CellNull : <div className="badge badge-primary">{rowData.client_name}</div>}</td>
            <td>{!rowData.mac_address_nexim ? CellNull : <div className="badge badge-info">{rowData.mac_address_nexim}</div>}</td>
            <td>{!rowData.delivery_type ? CellNull : <div className="bg-gray-800 rounded-lg p-2 font-bold text-normal text-white">{rowData.delivery_type}</div>}</td>
            <td>{!rowData.software_version ? CellNull : <div className="badge badge-accent">{rowData.software_version}</div>}</td>
            <td>{!rowData.delivery_date ? CellNull : <div className="badge badge-outline badge-base-100">{rowData.delivery_date.slice(0, 10)}</div>}</td>
            <td>{!rowData.engagement_date ? CellNull : <div className="badge badge-warning">{rowData.engagement_date.slice(0, 10)}</div>}</td>
            <td>
                <button
                    disabled={!(!rowData.delivery_date && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowUpdate(true)}
                    className="btn btn-square btn-outline btn-sm btn-info mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                    </svg>
                </button>
                <button
                    disabled={!(!rowData.delivery_date && rowData.serialnumber && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowPass(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                    </svg>
                </button>
                <button
                    disabled={!(!rowData.delivery_date && isAdmin.userLcuManagerRole === 'admin')}
                    onClick={() => setShowDelete(true)}
                    className="btn btn-square btn-outline btn-sm btn-error ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                    </svg>
                </button>
                {
                    showPass ?
                        <SetLcuDeliveryDate
                            handleForm={setShowPass}
                            refreshPage={dashboardRequest}
                            lcuDeliveryId= {parseInt(rowData.lcu_delivery_id)} /> :
                        null
                }
                {
                    showUpdate ?
                        <AddAndUpdateLcuDelivery
                            handleForm={setShowUpdate}
                            refreshPage={dashboardRequest}
                            url='/update_lcu_delivery'
                            defaultBody={{
                                lcuDeliveryId: parseInt(rowData.lcu_delivery_id),
                                deliveryTypeId: parseInt(rowData.lcu_delivery_type_id),
                                clientId: parseInt(rowData.client_id),
                                lcuId: parseInt(rowData.lcu_id),
                                softwareVersionId: parseInt(rowData.lcu_software_version_id),
                                datetime_obj: new Date(rowData.engagement_date),
                                datetime: format(new Date(rowData.engagement_date), dateFormatDB),
                            }} /> :
                        null
                }
                {
                    showDelete ?
                        <DeleteCard
                            show={showDelete}
                            handleForm={setShowDelete}
                            id={rowData.lcu_delivery_id}
                            dashboardRequest={dashboardRequest}
                            name="lcudelivery" /> :
                        null
                }
            </td>
        </tr>
    );
}