import { useEffect, useState } from "react";
import { getUserData } from "../../utils/utils";
import useFetch from "../../hooks/useFetch";
import AddAndUpdatePartnumber from "../../components/form/AddAndUpdatePartnumber";
import TableOrGrid from "../../components/tables/TableOrGrid";
import LcuPartnumberRow from "../../components/tables/LcuPartnumberRow";
import BarLcuPartnumbers from "../../components/ui/BarLcuPartnumbers";

const getSumList = (data, key) => {
    const bomCost = data.reduce((total, row) => {
        return total + parseFloat(row[key])
    }, 0)   

    return bomCost;
}

export default function LcuPartnumbers() {
    const [showEdit, setShowEdit] = useState(false);
    const [pn, setPn] = useState("");
    const userInfo = getUserData();

    const dataTable = useFetch(`${process.env.REACT_APP_API_URL}get_partnumbers`, { partnumber: pn })
    useEffect(() => {
        dataTable.handleRequest()
    }, [])

    return (
        <div className="w-full">
            <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                    <div className="stat-title">Stock Qty</div>
                    <div className="stat-value">{getSumList(dataTable.data, "stock_qty")}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Stock Cost</div>
                    <div className="stat-value">${getSumList(dataTable.data, "stock_cost")}</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Parts Numbers</div>
                    <div className="stat-value">{dataTable.data.length}</div>
                </div>
            </div>
            <BarLcuPartnumbers
                refreshPage={dataTable.handleRequest}
                loading={dataTable.loading}
                search={pn}
                setSearch={setPn}
                userInfo={userInfo} />
            <TableOrGrid
                loading={dataTable.loading}
                isGrid={false}
                data={dataTable.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={dataTable.handleRequest}
                TableRow={LcuPartnumberRow}
                TableColumns={<tr>
                    <th>Id</th>
                    <th>Image</th>
                    <th>PartNumber</th>
                    <th>Description</th>
                    <th>Vendor</th>
                    <th>Manufacturer</th>
                    <th>Category</th>
                    <th>Link</th>
                    <th>Stock Qty</th>
                    <th>Stock Cost</th>
                    <th>Unit Cost</th>
                    <th>Location</th>
                    <th>Created</th>
                    <th></th>
                </tr>} />
            {
                showEdit ?
                    <AddAndUpdatePartnumber
                        handleForm={setShowEdit}
                        dashboardRequest={dataTable.handleRequest}
                        url="add_partnumber" /> : null
            }
        </div>
    );
}