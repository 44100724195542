import { useState } from "react";
import useFetchSelectorNoStorage from "../../hooks/useFetchSelectorNoStorage";
import BasicSelectorId from "../inputs/BasicSelectorId";
import FujiScannerImmexInput from "../inputs/FujiScannerImmexInput";
import useFetchClickForm from "../../hooks/useFetchClickForm";
import NumericInput from "../inputs/NumericInput";

export default function ImmexPedimentoPartnumberAudit(props) {
    const { handleForm, itemData, dashboardRequest } = props;
    const [auditFlag, setAuditFlag] = useState(itemData.jp_id ? false : true);
    const [misingsProps, setMisingsProps] = useState("")
    const [qrMessageError, setQrMessageError] = useState("");
    const [qrMessageSuccess, setQrMessageSuccess] = useState("");
    const [bodyFetch, setBodyFetch] = useState({
        location_id: parseInt(itemData.immex_location_id) ? parseInt(itemData.immex_location_id) : 0,
        stock_qty: parseInt(itemData.current_qty),
        id: itemData.immex_pedimento_partnumber_id
    });
    const locationsList = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_immex_location_selector`)
    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }

    const addPartidaAudit = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}set_audit_pedimento_partida`,
        bodyFetch, dashboardRequest, handleForm);

    const checkQr = (partnumber, qty, jpid) => {
        if (partnumber !== itemData.partnumber) {
            setQrMessageError(`Wrong Partnumber:${partnumber}, Expected value:${itemData.partnumber}`)
            setQrMessageSuccess("")
        }
        else if (qty !== itemData.package_qty) {
            setQrMessageError(`Wrong Quantity:${qty}, Expected value:${itemData.package_qty}`)
            setQrMessageSuccess("")
        }
        else if (jpid !== itemData.jp_id) {
            setQrMessageError(`Wrong Quantity:${jpid}, Expected value:${itemData.jp_id}`)
            setQrMessageSuccess("")
        }
        else {
            setQrMessageSuccess("Success QR Audit")
            setQrMessageError("")
            setAuditFlag(true);
        }
    }
    const verifyBody = () => {
        if (!parseInt(bodyFetch.location_id)) setMisingsProps("Warning: Select a location!")
        else if (parseInt(bodyFetch.stock_qty) > parseInt(itemData.package_qty)) setMisingsProps("Warning: Stock qty is higher than package qty!")
        else {
            setMisingsProps("")
            addPartidaAudit.handleRequest()
        }
    }
    return (
        <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
                <div className="bg-base-200 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                    <h3 className="font-bold text-lg mb-4">Audit Partida</h3>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                    <form onSubmit={e => { e.preventDefault(); }} >
                        <div className="rounded-md bg-gray-800 p-2">
                            <h1 className="text-xl font-bold text-white">Partnumber: <br />
                                <p className="text-yellow-400">{itemData.partnumber}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Description: <br />
                                <p className="text-yellow-400">{itemData.description}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Metric Unit: <br />
                                <p className="text-yellow-400">{itemData.metric_unit}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">JP ID: <br />
                                <p className="text-yellow-400">{itemData.jp_id ? itemData.jp_id : "EMPTY"}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Package Qty: <br />
                                <p className="text-yellow-400">{itemData.package_qty}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Stock Qty: <br />
                                <p className="text-yellow-400">{itemData.current_qty}</p>
                            </h1>
                            <h1 className="text-xl font-bold text-white">Location: <br />
                                <p className="text-yellow-400">{!itemData.location ? "EMPTY" : itemData.location}</p>
                            </h1>
                        </div>
                        <div className="mb-4 mt-2">
                            <BasicSelectorId
                                disable={locationsList.data.length ? false : true}
                                title="Location"
                                keyName="location_id"
                                selectorHandle={selectorHandle}
                                value={bodyFetch.location_id}
                                data={locationsList.data} />
                        </div>
                        <div className="mb-4">
                            <NumericInput
                                title="Stock Quantity"
                                value={bodyFetch.stock_qty}
                                keyName="stock_qty"
                                selectorHandle={selectorHandle}
                                step="1"
                                min="0" />
                        </div>

                        {
                            qrMessageError ?
                                <div role="alert" className="flex rounded-lg w-full alert alert-warning mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 shrink-0 stroke-current"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>{qrMessageError}</span>
                                </div> : null
                        }
                        {
                            qrMessageSuccess ?
                                <div role="alert" className="flex rounded-lg w-full alert alert-success mb-4">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 shrink-0 stroke-current"
                                        fill="none"
                                        viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span>{qrMessageSuccess}</span>
                                </div> : null
                        }

                        {/*
                            !auditFlag && itemData.jp_id ?
                                <div className="form-control mb-4">
                                    <label for="large-input" class="block mb-2 text-sm font-medium">FUJI QR Checker</label>
                                    <FujiScannerImmexInput checkQr={checkQr} setQrMessageError={setQrMessageError} />
                                </div> :
                                null
                        */}
                        {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 shrink-0 stroke-current"
                                fill="none"
                                viewBox="0 0 24 24">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            <span>{misingsProps}</span>
                        </div>)}
                        {
                            (addPartidaAudit.loading) &&
                            (<progress className="progress w-full progress-info mb-4"></progress>)
                        }
                        <button
                            onClick={() => verifyBody()}
                            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                        >
                            Submit Audit
                        </button>
                        {/*
                            auditFlag ?
                                <button
                                    onClick={() => verifyBody()}
                                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                                >
                                    Submit Audit
                                </button> :
                                null
                        */}

                    </form>
                </div>
            </div>
        </>
    );
}


