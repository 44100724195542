import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import ImmexPedimentoRow from "../../components/tables/ImmexPedimentoRow";
import ImmexPedimentoPartnumberRow from "../../components/tables/ImmexPedimentoPartnumberRow";
import HeadStatsImmexPedimento from "../../components/ui/HeadStatsImmexPedimento";

import { getUserData } from "../../utils/utils";
import TableOrGrid from "../../components/tables/TableOrGrid";
import BarImmexPedimentos from "../../components/ui/BarImmexPedimentos";
import BarImmexPedimentosPartnumber from "../../components/ui/BarImmexPedimentosPartnumber";


export default function ImmexPedimento() {
    const [partnumber, setPartnumber] = useState("");
    const [pedimento, setPedimento] = useState("");
    const [pedimentoSelected, setPedimentoSelected] = useState(null);

    const pedimentosTable = useFetch(`${process.env.REACT_APP_API_URL}get_pedimentos`, { pedimento: pedimento });
    
    const partnumbersTable = useFetch(`${process.env.REACT_APP_API_URL}get_pedimentos_partnumber`, 
        { 
            id: pedimentoSelected?pedimentoSelected.pedimento_id:0, 
            partnumber: partnumber 
        });

    const userInfo = getUserData();

    useEffect(() => {
        if (pedimentoSelected) {
            partnumbersTable.handleRequest();
        }
        else {
            pedimentosTable.handleRequest();
        }
    }, [pedimentoSelected])

    const refreshAll = () =>{
        partnumbersTable.handleRequest();
        pedimentosTable.handleRequest();
    }
    return (
        <div className="w-full">
            <HeadStatsImmexPedimento data={pedimentosTable.data}/>

            {
                !pedimentoSelected ?
                    <>
                        <BarImmexPedimentos refreshPage={pedimentosTable.handleRequest}
                            setPedimento={setPedimento}
                            pedimento={pedimento}
                            userInfo={userInfo} />
                        <TableOrGrid
                            loading={pedimentosTable.loading}
                            isGrid={false}
                            data={pedimentosTable.data}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={pedimentosTable.handleRequest}
                            TableRow={ImmexPedimentoRow}
                            TableColumns={<tr>
                                <th>Pedimento</th>
                                <th>Temporary</th>
                                <th>Import Date</th>
                                <th>MAX Export Date</th>
                                <th>Export Date</th>
                                <th>Months Passed</th>
                                <th>Partidas</th>
                                <th>Locations</th>
                                <th>Audit</th>
                                <th>Partnumbers</th>
                                <th>Package Qty</th>
                                <th>Stock Qty</th>
                                <th>Package Price</th>
                                <th>Sold Price</th>
                                <th></th>
                            </tr>}
                            setPedimentoSelected={setPedimentoSelected} />
                    </> :
                    <>
                        <BarImmexPedimentosPartnumber refreshPage={refreshAll}
                            setPartnumber={setPartnumber}
                            partnumber={partnumber}
                            setPedimentoSelected={setPedimentoSelected}
                            pedimentoId={pedimentoSelected.pedimento_id}
                            userInfo={userInfo} />
                        <TableOrGrid
                            pagerButton={false}
                            pagerTop={false}
                            loading={pedimentosTable.loading}
                            isGrid={false}
                            data={pedimentosTable.data.filter((rowPedimento)=>rowPedimento.pedimento_id===pedimentoSelected.pedimento_id)}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={null}
                            TableRow={ImmexPedimentoRow}
                            TableColumns={<tr>
                                <th>Pedimento</th>
                                <th>Temporary</th>
                                <th>Import Date</th>
                                <th>MAX Export Date</th>
                                <th>Export Date</th>
                                <th>Months Passed</th>
                                <th>Partidas</th>
                                <th>Locations</th>
                                <th>Audit</th>
                                <th>Partnumbers</th>
                                <th>Package Qty</th>
                                <th>Stock Qty</th>
                                <th>Package Price</th>
                                <th>Sold Price</th>
                            </tr>}
                            setPedimentoSelected={setPedimentoSelected}
                            noActions={true} />
                        <TableOrGrid
                            pagerButton={false}
                            loading={partnumbersTable.loading}
                            isGrid={false}
                            data={partnumbersTable.data}
                            GridCard={null}
                            isAdmin={userInfo}
                            dashboardRequest={refreshAll}
                            TableRow={ImmexPedimentoPartnumberRow}
                            TableColumns={<tr>
                                <th>Image</th>
                                <th>PartNumber</th>
                                <th>Location</th>
                                <th>Description</th>
                                <th>Metric Unit</th>
                                <th>HTS</th>
                                <th>JP ID</th>
                                <th>Audit</th>
                                <th>Package Qty</th>
                                <th>Stock Qty</th>
                                <th>Unit Price</th>
                                <th></th>
                            </tr>} />
                    </>
            }
        </div>
    );
}