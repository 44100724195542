import { useState, useEffect } from "react";

import HeadStatsIndividual from "../components/ui/HeadStatsIndividual";
import BadgeFilter from "../components/ui/BadgeFilters";
import FilterBudgetSearch from "../components/form/FilterBudgetSearch";
import GridTable from "../components/tables/GridTable";
import BudgetCard from "../components/tables/BudgetCard";
import AddAndUpdateBudget from "../components/form/AddAndUpdateBudget";
import AlertMain from "../components/ui/AlertMain";
import useFetch from "../hooks/useFetch";
import MonthPicker from "../components/inputs/MonthPicker";

import {
    getUserData, cardsBuilder,
    searchBody, monthDateWithThreshold
} from "../utils/utils";
import { dateFormatDB } from "../utils/constants";
import { format } from "date-fns";

export const bodyToAddNewExpense = (id) => {
    return {
        "id": id,
        "principal": "",
        "client_name": "",
        "budget_type": "",
        "datetime_obj": new Date(),
        "datetime": format(new Date(), dateFormatDB),
        "amount": 0,
        "reason": ""
    }
}

export default function BudgetUser() {
    const [showSearch, setShowSearch] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    const dayThreshold = 25;
    const userInfo = getUserData();

    const { from, to } = monthDateWithThreshold(new Date(), dayThreshold)
    const [filterValues, setFilterValues] = useState(
        searchBody(userInfo.isAdmin ? 0 : userInfo.userId,
            userInfo.isAdmin ? "ALL" : userInfo.userName, from, to)
    );

    const { data, loading, error, handleRequest } = useFetch(`${process.env.REACT_APP_API_URL}budget_cards`,
        filterValues
    )
    useEffect(() => {
        handleRequest();
    }, [filterValues])

    const dateHandle = (dt) => {
        const { from, to } = monthDateWithThreshold(dt, dayThreshold)
        setFilterValues({
            ...filterValues,
            "datetime": dt,
            "from": from,
            "to": to
        })
    }
    return (
        <div className="p-4">
            <HeadStatsIndividual
                data={data}
                dayThreshold={dayThreshold}
                name="Budget" />
            <BadgeFilter
                values={filterValues}
                handleForm={setShowSearch}
                loading={loading}
                skipList={["user_id", "expense_type"]} />
            <AlertMain error={error} text={"Error! budget_cards."} />
            <MonthPicker
                title="Select Month"
                setSelectMonth={dateHandle}
                selectMonth={filterValues.datetime}
                hiddenTitle={true}
                style="form-control mt-4" />
            <GridTable
                title="Budgets"
                Cards={() => cardsBuilder(BudgetCard,
                    data,
                    userInfo.isAdmin,
                    handleRequest)}
                addClickHandle={setShowAdd}
                filterValues={filterValues}
                dashboardRequest={handleRequest}
                cardsData={data}
                disableReport={true} />
            <AddAndUpdateBudget
                show={showAdd}
                handleForm={setShowAdd}
                userName={userInfo.userName}
                dashboardRequest={handleRequest}
                startBody={bodyToAddNewExpense(userInfo.userId)}
                url={'add_budget'}
                title="Add Budget" />
            <FilterBudgetSearch
                show={showSearch}
                handleForm={setShowSearch}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                isAdmin={userInfo.isAdmin}
                dayThreshold={dayThreshold}
            />
        </div>
    )
}