import { useState } from "react";
import UserWallEvent from "../form/UserWallEvent"
import IdSelector from "../inputs/IdSelector";
import AddAndUpdateUser from "../form/AddAndUpdateUser";
export default function OrganizationButtons({ organization_id, dashboardRequest, setOrgId, orgId }) {
    const [showEvent, setShowEvent] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const selectorHandle = (e) => {
        setOrgId(e.target.value);
    }
    const userOrganizations = JSON.parse(sessionStorage.getItem("user_organizations"));
    return (
        <div className="flex bg-base-100 rounded-md p-2 mb-4">
                        <IdSelector
                title="Oranization"
                data={userOrganizations}
                selectorHandle={selectorHandle}
                value={orgId}
                defaultVal={organization_id}/>
            <button 
                onClick={() => setShowUser(true)}
                className="btn btn-primary btn-outline mx-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 12h4m-2 2v-4M4 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                Add User
            </button>
            <button
                onClick={() => setShowEvent(true)}
                className="btn btn-secondary btn-outline mr-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z" />
                </svg>
                Add Event
            </button>

            <UserWallEvent
                organization_id={orgId}
                handleForm={setShowEvent}
                show={showEvent}
                dashboardRequest={dashboardRequest} />
            <AddAndUpdateUser
                organization_id={orgId}
                handleForm={setShowUser}
                show={showUser}
                dashboardRequest={dashboardRequest} />
        </div>
    )
}