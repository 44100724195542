import { useState } from 'react';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import DatePicker from '../inputs/DatePicker';
import { format } from 'date-fns';
import { dateFormatDB } from '../../utils/constants';

export default function SetLcuDeliveryDate(props) {
    const { handleForm, refreshPage, lcuDeliveryId } = props;
    const [bodyFetch, setBodyFetch] = useState({
        lcuDeliveryId: lcuDeliveryId,
        datetime_obj: new Date(),
        datetime: format(new Date(), dateFormatDB),
    });
    const dateHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "datetime_obj": dt,
            "datetime": format(dt, dateFormatDB),
        })
    }
    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}set_lcu_delivery_date`, bodyFetch, refreshPage, handleForm);

    const verifyBody = () => {
        handleRequest()
    }

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-base-100 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">Delivery Pass</h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <div className="mb-4">
                        <DatePicker
                            title="Delivery Date"
                            setSelectDate={dateHandle}
                            selectedDate={bodyFetch.datetime_obj} />
                    </div >
                    {error && (<div role="alert" className="flex rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Write Fail</span>
                    </div>)}
                    {
                        (loading) &&
                        (<progress className="progress w-full progress-info mb-4"></progress>)
                    }
                    <button
                        onClick={verifyBody}
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>

    );
};

