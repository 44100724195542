export default function TableRows(props) {
    const { rowsData, TableRow, Columns } = props;
    return (
        <>
            {
                rowsData.length ?
                    <div className="overflow-x-auto border-solid border-2 border-gray-400 rounded-lg">
                        <table className="table table-xs table-zebra">
                            <thead className="border-b-2 border-gray-400 bg-base-100">
                                {Columns}
                            </thead>
                            <tbody>
                                {
                                    rowsData.map((value, ind) =>
                                        <TableRow key={ind} rowData={value} {...props} />
                                    )
                                }
                            </tbody>
                        </table>
                    </div> :
                    null
            }
        </>

    );
}