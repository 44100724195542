import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarDays,
	faChevronDown,
	faUserShield,
	faUser,
	faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function SelectTyC(props) {
	const { changeTab, usersSelected } = props;
	const [isActive, setIsActive] = useState(false);

	const endpoint = process.env.REACT_APP_API_URL + "/tyc";
	const { data, loading, error, refetch } = useFetchGet(endpoint);
	console.log(data);

	if (loading) return <span class="loading loading-dots loading-xs"></span>;

	if (error) console.error(error);

	return (
		<>
			<select className="border border-[#cbcbcb] pl-2 box-border h-[38px] rounded-[4px] focus:border-2 focus:border-blue-500 focus:outline-none" id="tyc" name="tyc">
                <option disabled selected>
                    Select TyC
                </option>
                {data ? data.data.map(tyc => (
                    <option data-tycid={tyc.id} value={tyc.id}>{tyc.name}</option>
                )) : <></>}
		    </select>
		</>
	);
}

export default SelectTyC ;
