import useFetchGet from "../../hooks/useFetchGet";
import { useState } from "react";
import SelectClients from "../../components/form/selectClients";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faChevronDown,
    faUserShield,
    faUser,
    faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function PoChangeForm(props) {
    const { row } = props;
    const [isActive, setIsActive] = useState(false);
    
    var curr = new Date();
	curr.setDate(curr.getDate());
	var date = curr.toISOString().substring(0,10);


    /*const options = [
        { value: "chocolate", label: "Chocolate", custom: "dsa" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];
    const [selectedOption, setSelectedOption] = useState([]);
    changeTab(selectedOption);

    console.log(selectedOption);

    var currentArea = "";
    const endpoint = process.env.REACT_APP_API_URL + "clientsForSelect";
    const { data, loading, error, refetch } = useFetchGet(endpoint, {
        appName: "schedule",
    });
    //console.log(data);

    if (loading) return <h1>LOADING...</h1>;

    if (error) console.error(error);*/

    const handleCost = (e) => {
        var cost = document.getElementById("cost").value;
        var costBefore = document.getElementById("costBefore").value;
        
        var total = costBefore - cost

        document.getElementById("costAfter").value = total
        
        ///calculateTotal()
        
    };

    const handleHours = (e) => {
        var hours = document.getElementById("hours").value;
        var hoursBefore = document.getElementById("hoursBefore").value;
        //var hoursAfter = document.getElementById("hoursAfter").value;

        var total = hoursBefore - hours

        document.getElementById("hoursAfter").value = total
        
        //calculateTotal()
        
    };

    return (
        <form>
        <div className="border-1 grid grid-cols-2 gap-6 p-2">
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Id</span>
                </label>
                <input
                    type="text"
                    className= "input input-bordered w-full"
                    name="id"
                    id="id"
                    disabled
                    //defaultValue={row ? row.id : ""}
                />
            </div>

            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">PO Id</span>
                </label>
                <input
                    type="text"
                    className= "input input-bordered w-full"
                    name="poId"
                    id="poId"
                    disabled
                    defaultValue={row ? row.id : ""}
                />
            </div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Report Id</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="reportId"
                    id="reportId"
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">UserName</span>
				</label>
				<input
					type="text"
					placeholder="Type here"
					className="input input-bordered w-full "
                    disabled
					name="userName"
                    id="userName"
                    defaultValue={localStorage.getItem("userName")}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Concept</span>
				</label>
				<input
					type="text"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="concept"
                    id="concept"
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Hours</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="hours"
                    id="hours"
                    onChange={handleHours}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Hours Before</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="hoursBefore"
                    id="hoursBefore"
                    disabled
                    defaultValue={row.hoursLeft}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Hours After</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="hoursAfter"
                    id="hoursAfter"
                    disabled
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Cost</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="cost"
                    id="cost"
                    onChange={handleCost}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Cost Before</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="costBefore"
                    id="costBefore"
                    disabled
                    defaultValue={row.costLeft}
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Cost After</span>
				</label>
				<input
					type="number"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="costAfter"
                    id="costAfter"
                    disabled
				/>
			</div>

            <div className="form-control w-full ">
				<label className="label">
					<span className="label-text">Date</span>
				</label>
				<input
					type="date"
					placeholder="Type here"
					className="input input-bordered w-full "
					name="date"
                    id="date"
                    defaultValue={date} 
				/>
			</div>

							
        

            </div>
        </form>
    );
}

export default PoChangeForm;
