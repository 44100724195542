import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../../components/tables/TableOrGrid";
import HeadStatsCheckout from "../../components/ui/HeadStatsCheckout";
import BarImmexCheckout from "../../components/ui/BarImmexCheckout";
import { getUserData } from "../../utils/utils";
import ImmexCheckoutRow from "../../components/tables/ImmexCheckoutRow";

const sumAuthorized = (data, prop) => {
    return data.filter((row) => row[prop] ? true : false).length
}

export default function ImmexCheckout() {
    const userInfo = getUserData();
    const getCheckouts = useFetch(`${process.env.REACT_APP_API_URL}get_getckouts`, {
        isAdmin: userInfo.userImmexRole === "admin",
        userId: userInfo.userId,
    })
    useEffect(() => {
        getCheckouts.handleRequest();
    }, [])

    return (
        <div className="w-full">
            <HeadStatsCheckout
                checkouts={getCheckouts.data.length}
                process={getCheckouts.data.length - sumAuthorized(getCheckouts.data, "delivery_datetime")}
                complete={sumAuthorized(getCheckouts.data, "delivery_datetime")} />
            <BarImmexCheckout refreshPage={getCheckouts.handleRequest} />
            <TableOrGrid
                loading={getCheckouts.loading}
                isGrid={false}
                data={getCheckouts.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={getCheckouts.handleRequest}
                TableRow={ImmexCheckoutRow}
                TableColumns={<tr>
                    <th>ID</th>
                    <th>User Name</th>
                    <th>Client Name</th>
                    <th>Purchase Order</th>
                    <th>Total Qty</th>
                    <th>Partnumbers</th>
                    <th>Create</th>
                    <th>Pickup</th>
                    <th>Delivery</th>
                    <th></th>
                </tr>} />
        </div>
    );
}