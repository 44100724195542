import { moneyKHandle, sumValuesByProperty, 
    sumRowsByProperty, countRowsByProperty, separator } from "../../utils/utils";
import CountDown from "./CountDown";

export default function HeadStatsHome(props) {
    const { expenseData, budgetData } = props;
    
    return (
        <div className="lg:flex lg:justify-center mt-2 w-full">
            <div className="grid lg:grid-flow-col lg:auto-cols-auto gap-4 grid-flow-row auto-rows-max">
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-amber-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Required Budget</div>
                    <div className="stat-value text-amber-400 text-2xl pt-2">${separator(moneyKHandle(sumValuesByProperty(budgetData, "amount")))}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z"></path></svg>
                    </div>

                    <div className="stat-title">Authorized Budget</div>
                    <div className="stat-value text-green-400 text-2xl pt-2">${separator(moneyKHandle(sumRowsByProperty(budgetData, "status", "APPROVED")))}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-title">Time Left </div>
                    <CountDown dayThreshold={25}/>
                </div>

                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-amber-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Total Expenses</div>
                    <div className="stat-value text-amber-400 text-2xl pt-2">${separator(moneyKHandle(sumValuesByProperty(expenseData, "amount")))}</div>
                </div>
                <div className="stat bg-base-100 rounded-xl">
                    <div className="stat-figure text-green-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V6a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1M3 18v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path></svg>
                    </div>
                    <div className="stat-title">Authorized Expenses</div>
                    <div className="stat-value text-green-400 text-2xl pt-2">${separator(moneyKHandle(sumRowsByProperty(expenseData, "status", "APPROVED")))}</div>
                </div>
            </div>

        </div>
    );
}