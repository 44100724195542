import { useState } from 'react';
import useFetchClickForm from '../../hooks/useFetchClickForm';
import useFetchSelectorNoStorage from '../../hooks/useFetchSelectorNoStorage';
import BasicSelectorId from '../inputs/BasicSelectorId';
import DatePicker from '../inputs/DatePicker';
import { format } from 'date-fns';
import { dateFormatDB } from '../../utils/constants';

export default function AddAndUpdateLcuDelivery(props) {
    const { handleForm, refreshPage, url, defaultBody = {
        lcuDeliveryId: 0,
        deliveryTypeId: 0,
        clientId: 0,
        lcuId: 0,
        softwareVersionId: 0,
        datetime_obj: new Date(),
        datetime: format(new Date(), dateFormatDB),
    } } = props;
    const [misingsProps, setMisingsProps] = useState("");
    const [bodyFetch, setBodyFetch] = useState(defaultBody);

    const deliveryTypes = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_delivery_type_selector`);
    const softwareVersions = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_software_version_selector`);
    const lcuBoxes = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_lcu_selector`);
    const clients = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}clients_selector`);

    const selectorHandle = (keyName, value) => {
        setBodyFetch({
            ...bodyFetch,
            [keyName]: value,
        })
    }
    const dateHandle = (dt) => {
        setBodyFetch({
            ...bodyFetch,
            "datetime_obj": dt,
            "datetime": format(dt, dateFormatDB),
        })
    }
    const { loading, error, handleRequest } = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}${url}`, bodyFetch, refreshPage, handleForm);

    const verifyBody = () => {
        if (!bodyFetch.clientId) setMisingsProps("Warning: Select a client!")
        else if (!bodyFetch.deliveryTypeId) setMisingsProps("Warning: Select a Delivery Type!")
        else {
            setMisingsProps("")
            handleRequest()
        }
    }

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-base-100 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">{bodyFetch.lcuDeliveryId ? "Update Computer" : "Add Computer"} </h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="LCU"
                            value={bodyFetch.lcuId}
                            keyName="lcuId"
                            selectorHandle={selectorHandle}
                            data={lcuBoxes.data}
                            noneSelect={true}
                        />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Client"
                            value={bodyFetch.clientId}
                            keyName="clientId"
                            selectorHandle={selectorHandle}
                            data={clients.data}
                            noneSelect={true}
                        />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Delivery Type"
                            value={bodyFetch.deliveryTypeId}
                            keyName="deliveryTypeId"
                            selectorHandle={selectorHandle}
                            data={deliveryTypes.data}
                            noneSelect={true}
                        />
                    </div>
                    <div className="mb-4">
                        <BasicSelectorId
                            title="Software Version"
                            value={bodyFetch.softwareVersionId}
                            keyName="softwareVersionId"
                            selectorHandle={selectorHandle}
                            data={softwareVersions.data}
                            noneSelect={true}
                        />
                    </div>
                    <div className="mb-4">
                        <DatePicker
                            title="Engagement Date"
                            setSelectDate={dateHandle}
                            selectedDate={bodyFetch.datetime_obj} />
                    </div >

                    {misingsProps && (<div role="alert" className="flex rounded-lg w-full mb-6 alert alert-warning">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span>{misingsProps}</span>
                    </div>)}
                    {error && (<div role="alert" className="flex rounded-lg w-full alert alert-error">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Write Fail</span>
                    </div>)}
                    {
                        (loading) &&
                        (<progress className="progress w-full progress-info mb-4"></progress>)
                    }
                    <button
                        onClick={verifyBody}
                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>

    );
};

