import { useState } from "react";
import PartViewer from "../form/PartViewer";
import ImmexCheckoutItemChecker from "../form/ImmerCheckoutItemChecker";

export default function ImmexCheckoutItemsRow({ rowData, dashboardRequest }) {
    const [showImage, setShowImage] = useState(false);
    const [showItemChecker, setShowItemChecker] = useState(false);

    return (
        <tr className="hover">
            <td>
                <button
                    disabled={rowData.file_name === null}
                    onClick={() => setShowImage(true)}
                    className="btn btn-square btn-outline btn-sm btn-success mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m3 16 5-7 6 6.5m6.5 2.5L16 13l-4.286 6M14 10h.01M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
                    </svg>
                </button>
            </td>
            <td><div className="bg-base-100 rounded-lg p-2 font-bold">{rowData.partnumber}</div></td>
            <td><div className="badge badge-outline">{rowData.qty}</div></td>
            <td><div className="badge badge-outline">{rowData.discount_qty}</div></td>
            <td>
                <div className="flex">
                    <button
                        onClick={()=>setShowItemChecker(true)}
                        className={parseInt(rowData.discount_qty)===parseInt(rowData.qty)?
                        "btn btn-square btn-outline btn-sm btn-success ":
                        "btn btn-square btn-outline btn-sm btn-warning "}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                        </svg>
                    </button>
                    {
                        showImage ?
                            <PartViewer
                                handleForm={setShowImage}
                                id={rowData.immex_partnumber_id}
                                url="get_partnumber_image_immex" /> :
                            null
                    }
                    {
                        showItemChecker?
                        <ImmexCheckoutItemChecker
                        handleForm={setShowItemChecker}
                        itemData = {rowData}
                        dashboardRequest={dashboardRequest}
                        />:
                        null
                    }
                </div>

            </td>
        </tr>
    );
}