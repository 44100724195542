import { useRef } from "react";
import * as XLSX from 'xlsx';

const columnsFile = [
    "partnumber",
    "description",
    "metric_unit",
    "qty",
    "unit_price",
    "hts",
    "jp_id"
]


const tempVal = {
    "IN": true,
    "A1": false
}

const ExcelFilePedimento = ({ setFileTable, setPedimentoInfo, fileTable, setErrorFile, addPedimentosFile }) => {
    

    const inputFile = useRef(null);

    // Function to reset the input element
    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];  // Get the uploaded file

        if (!file) return;  // If no file is selected, exit the function

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Get the first sheet
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            // Convert the sheet to JSON
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false });

            const headers = jsonData[0];
            if (headers.length) {
                const checkColumns = columnsFile.map((column) => headers.includes(column))
                if (checkColumns.includes(false)) {
                    setErrorFile(`Columns Missing:${headers}`)
                }
                else {

                    if (jsonData.length > 1) {
                        const rows = jsonData.slice(1).map((row) => {
                            let obj = {};
                            headers.forEach((header, index) => {
                                obj[header] = row[index];
                            });
                            return obj;
                        });
                        // Set the data into state
                        const pInfo = file.name.split('_');
                        setPedimentoInfo({ pedimento: pInfo[0], importDate: pInfo[2].split('.')[0], isTemporary: tempVal[pInfo[1]] });
                        setFileTable(rows);
                        setErrorFile("");
                    }
                    else {
                        setErrorFile("No rows")
                    }
                }
            }
            else {
                setErrorFile(`No Columns Detected`)
            }
        };

        reader.readAsArrayBuffer(file);
    };
    return (
        <div className="flex flex-row">
            <input
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                ref={inputFile}
                onClick={handleReset}
                type="file"
                className="file-input file-input-bordered file-input-info " />
            <button
                onClick={()=>addPedimentosFile()}
                disabled={!fileTable.length}
                className="ml-4 btn btn-success">
                Import File
            </button>
        </div>
    );
};

export default ExcelFilePedimento;
