import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import TableOrGrid from "../../components/tables/TableOrGrid";
import LcuCpuRow from "../../components/tables/LcuCpuRow";
import BarLcuCpu from "../../components/ui/BarLcuCpu";
import { getUserData } from "../../utils/utils";

export default function LcuCPU() {
    const [searchLcu, setSearchLcu] = useState('');
    const userInfo= getUserData();
    const getCpus = useFetch(`${process.env.REACT_APP_API_URL}get_lcu_cpu`, {
        lcuBox:searchLcu
    })
    useEffect(() => {
        getCpus.handleRequest();
    }, [])

    return (
        <div className="w-full max-w-screen-2xl m-auto">
            <BarLcuCpu 
                userInfo={userInfo}
                searchLcu={searchLcu} 
                setSearchLcu={setSearchLcu}
                refreshPage={getCpus.handleRequest} 
                loading={getCpus.loading}/>
            <TableOrGrid
                loading={getCpus.loading}
                isGrid={false}
                data={getCpus.data}
                GridCard={null}
                isAdmin={userInfo}
                dashboardRequest={getCpus.handleRequest}
                TableRow={LcuCpuRow}
                TableColumns={<tr>
                    <th>CPU ID</th>
                    <th>Mac Address</th>
                    <th>WinKey</th>
                    <th>Created Date</th>
                    <th>LCU SN.</th>
                    <th>Client Name</th>
                    <th>Delivery Date</th>
                    <th>Action</th>
                </tr>} />
        </div>
    );
}