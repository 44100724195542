import { useState } from "react";
import AddAndUpdateLcuDelivery from "../form/AddAndUpdateLcuDelivery";
import IdSelector from "../inputs/IdSelector";
import useFetchSelectorNoStorage from "../../hooks/useFetchSelectorNoStorage";

export default function BarLcuBom({ refreshPage, loading, bomName, setBomName, userInfo }) {
    const [showAdd, setShowAdd] = useState(false);
    const { data: bomsData } = useFetchSelectorNoStorage(`${process.env.REACT_APP_API_URL}get_bom_selector`);
    const selectorHandleBom = (e) => {
        setBomName(e.target.value)
    }
    return (
        <div className="navbar bg-base-100 my-4 rounded-xl ">
            <div className="flex-1">
                <a className="btn btn-ghost text-xl">Bom</a>
            </div>
            <div className="navbar-end">
                <IdSelector
                    title='Bom'
                    value={bomName}
                    selectorHandle={selectorHandleBom}
                    data={bomsData}
                    style="input input-bordered border-gray-400 input-normal mr-2"
                />
                <button
                    onClick={() => refreshPage()}
                    className="btn btn-square btn-info ml-4">
                    {loading ?
                        <span className="loading loading-spinner"></span> :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                        </svg>
                    }

                </button>

                <button
                    disabled={!(userInfo.userLcuManagerRole === 'admin')}
                    onClick={() => setShowAdd(true)}
                    className="btn btn-square btn-success ml-4">

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                </button>
                {
                    showAdd ?
                        <AddAndUpdateLcuDelivery
                            handleForm={setShowAdd}
                            refreshPage={refreshPage}
                            url='/add_lcu_delivery' /> :
                        null
                }
            </div>
        </div>
    );
}