import { useState } from "react";
import ExcelFilePedimento from "../inputs/ExcelFilePedimento";
import TableOrGrid from "../tables/TableOrGrid";
import ImmexExcelPedimentoRow from "../tables/ImmexExcelPedimentoRow";
import useFetchClickForm from "../../hooks/useFetchClickForm";

export default function AddImmexPedimento(props) {
    const { handleForm, refreshPage } = props;
    const [fileTable, setFileTable] = useState([]);
    const [pedimentoInfo, setPedimentoInfo] = useState({ pedimento: null, importDate: null, isTemporary: null });
    const [errorFile, setErrorFile] = useState("");

    const addPedimentosFile = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}add_pedimentos_list_file`,
        { pedimentoInfo: pedimentoInfo, fileTable: fileTable }, refreshPage, handleForm);

    console.log(fileTable)
    console.log(pedimentoInfo)
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-base-100 p-6 rounded-lg shadow-lg relative w-full max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">Add Pedimento</h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>
                    <ExcelFilePedimento
                        setFileTable={setFileTable}
                        setPedimentoInfo={setPedimentoInfo}
                        fileTable={fileTable}
                        setErrorFile={setErrorFile}
                        addPedimentosFile={addPedimentosFile.handleRequest} />
                    {
                        addPedimentosFile.loading ?
                            <progress className="progress w-full"></progress> :
                            null
                    }
                    {
                        errorFile ?
                            <div role="alert" className="alert alert-error mt-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 shrink-0 stroke-current"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>{errorFile}</span>
                            </div> : null
                    }
                    {
                        addPedimentosFile.error ?
                            <div role="alert" className="alert alert-error mt-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 shrink-0 stroke-current"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>{addPedimentosFile.error}</span>
                            </div> : null
                    }
                    {
                        pedimentoInfo.pedimento ?
                            <div className="mt-6 bg-base-100 rounded-lg border-2 border-solid p-2 text-lg">
                                <div>Pedimento:{pedimentoInfo.pedimento}</div>
                                <div>Import Date:{pedimentoInfo.importDate}</div>
                                <div>Temporary:{pedimentoInfo.isTemporary ? "YES" : "NOT"}</div>
                            </div> : null
                    }

                    <TableOrGrid
                        pagerTop={false}
                        loading={false}
                        isGrid={false}
                        data={fileTable}
                        GridCard={null}
                        isAdmin={null}
                        dashboardRequest={null}
                        TableRow={ImmexExcelPedimentoRow}
                        TableColumns={<tr>
                            <th>Partnumber</th>
                            <th>Description</th>
                            <th>Metric Unit</th>
                            <th>Qty</th>
                            <th>Unit Price</th>
                            <th>HTS</th>
                            <th>JP ID</th>
                        </tr>} />
                </form>
            </div>
        </div>

    );
};

