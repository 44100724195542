import { useState, useEffect } from "react";
import TableOrGrid from "../tables/TableOrGrid";
import useFetchClickForm from "../../hooks/useFetchClickForm";
import useFetch from "../../hooks/useFetch";
import ImmexCheckoutItemsRow from "../tables/ImmexCheckoutItemsRow";

export default function ImmexPickup(props) {
    const { handleForm, refreshPage, checkoutData, withoutSubmit } = props;

    const setPickupCheckout = useFetchClickForm(
        `${process.env.REACT_APP_API_URL}set_checkout_pickup`,
        {id:checkoutData.immex_checkout_id}, refreshPage, handleForm);

    const getCheckoutItems = useFetch(`${process.env.REACT_APP_API_URL}get_checkout_items`,
        { checkoutId: checkoutData.immex_checkout_id })

    useEffect(() => {
        getCheckoutItems.handleRequest();
    }, [])

    const sumPickQty = getCheckoutItems.data.reduce((acomulator, value) => acomulator + parseInt(value.qty), 0);
    const sumCartQty = getCheckoutItems.data.reduce((acomulator, value) => acomulator + parseInt(value.discount_qty), 0);

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-hidden p-4">
            <div className="bg-base-100 p-6 rounded-lg shadow-lg relative w-full md:w-4/12 max-h-full overflow-y-auto">
                <h3 className="font-bold text-lg mb-4">Pick Up Parts</h3>
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={() => handleForm(false)}>✕</button>
                <form onSubmit={e => { e.preventDefault(); }}>

                    <label for="large-input" class="block mb-2 text-sm font-medium">Items</label>
                    <TableOrGrid
                        pagerButton={false}
                        pagerTop={false}
                        loading={false}
                        isGrid={false}
                        data={getCheckoutItems.data}
                        GridCard={null}
                        isAdmin={null}
                        dashboardRequest={getCheckoutItems.handleRequest}
                        TableRow={ImmexCheckoutItemsRow}
                        TableColumns={<tr>
                            <th>Image</th>
                            <th>Partnumber</th>
                            <th>Cart Qty</th>
                            <th>Pickup Qty</th>
                            <th>Actions</th>
                        </tr>} />
                    {
                        setPickupCheckout.loading ?
                            <progress className="progress w-full"></progress> :
                            null
                    }
                    {
                        setPickupCheckout.error ?
                            <div role="alert" className="alert alert-error mt-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 shrink-0 stroke-current"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span>{setPickupCheckout.error}</span>
                            </div> : null
                    }
                    {
                        sumCartQty===sumPickQty && !withoutSubmit?
                        <button
                            onClick={()=>setPickupCheckout.handleRequest()}
                            className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded rounded-lg focus:outline-none focus:shadow-outline"
                        >
                            Finish Pickup
                        </button>:null
                    }
                </form>
            </div>
        </div>

    );
};

